import { useCallback } from 'react'
import { Button, PlusIcon, useSnackbar } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'

import {
  ListPage,
  ListPageHeader,
  ListPageHeaderGroup,
  PageTitle,
  FiltersProvider,
  CustomerList,
  CustomerListFilters,
  AddCustomerModal
} from '@app/components'
import { useCustomerFilters } from '@app/components/CustomerList/CustomerFilters/filters'

import { DeleteCustomerDocument } from './__generated__/CustomersPage'

export const CustomersPage = () => {
  const { t } = useTranslation()

  const snackbar = useSnackbar()

  const [, removeCustomer] = useMutation(DeleteCustomerDocument)

  const handleRemoveCustomer = useCallback(
    (customerId: string) => {
      removeCustomer({
        input: {
          id: customerId
        }
      })
        .then(() => {
          snackbar.showSnackbar({
            variant: 'success',
            title: 'Customer removed'
          })
        })
        .catch(() => {
          snackbar.showSnackbar({
            variant: 'danger',
            title: 'Failed to remove customer'
          })
        })
    },
    [removeCustomer, snackbar]
  )

  const filtersContext = useCustomerFilters()

  return (
    <FiltersProvider value={filtersContext}>
      <ListPage>
        <ListPageHeaderGroup>
          <ListPageHeader>
            <PageTitle>{t('common:customers')}</PageTitle>
            <AddCustomerModal>
              <Button colorVariant='secondary' leftIcon={<PlusIcon />}>
                {t('customers:customersPage.addCustomerButton.label')}
              </Button>
            </AddCustomerModal>
          </ListPageHeader>

          <CustomerListFilters />
        </ListPageHeaderGroup>

        <CustomerList onRemoveCustomer={handleRemoveCustomer} />
      </ListPage>
    </FiltersProvider>
  )
}
