import {
  ValueOfFiltersConfig,
  filters,
  useFiltersContext,
  useFilters,
  queryFilter
} from '../../filters'

export const customerFilters = filters({
  query: queryFilter('Search')
})

export const useCustomerFilters = (
  initialValues: Partial<CustomerListFilters> = {}
) => useFilters(customerFilters, initialValues)

export type CustomerListFiltersConfig = typeof customerFilters

type CustomerListFilters = ValueOfFiltersConfig<typeof customerFilters>

export const useCustomerListFiltersContext = () =>
  useFiltersContext<CustomerListFiltersConfig>()
