import {
  FilterButton,
  PlusIcon,
  FilterButtonLabel,
  MenuButton,
  Menu,
  Flex,
  Spinner,
  MenuContent,
  MenuItem
} from '@woorcs/design-system'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { Suspense, useCallback } from 'react'
import {
  SubmissionFilenameComponent,
  SubmissionFilenameComponentType,
  SubmissionFilenameCustomerProperty,
  SubmissionFilenameDateFormat,
  SubmissionFilenameSubmitterProperty
} from '@woorcs/graphql/schema'
import { useTranslation } from 'react-i18next'

import { Field, SelectFieldModal } from './SelectFieldModal'

interface SelectFilenameComponentMenuProps {
  fields: Field[]
  onAdd: (component: SubmissionFilenameComponent) => void
}

export const SelectFilenameComponentMenu = ({
  fields,
  onAdd
}: SelectFilenameComponentMenuProps) => {
  const { t } = useTranslation('form-details')

  const componentTypes: {
    label: string
    value: SubmissionFilenameComponentType
  }[] = [
    {
      label: t('settings.exports.filenameFormatField.submitter'),
      value: SubmissionFilenameComponentType.Submitter
    },
    {
      label: t('settings.exports.filenameFormatField.customer'),
      value: SubmissionFilenameComponentType.Customer
    },
    {
      label: t('settings.exports.filenameFormatField.date'),
      value: SubmissionFilenameComponentType.Date
    },
    {
      label: t('settings.exports.filenameFormatField.field'),
      value: SubmissionFilenameComponentType.FormField
    }
  ]

  const handleMenuItemClick = useCallback(
    (type: SubmissionFilenameComponentType) => {
      if (type === SubmissionFilenameComponentType.FormField) {
        return
      }

      const getItem = (): SubmissionFilenameComponent => {
        switch (type) {
          case SubmissionFilenameComponentType.Customer:
            return {
              __typename: 'SubmissionFilenameCustomerComponent',
              customerProperty: SubmissionFilenameCustomerProperty.Name
            }
          case SubmissionFilenameComponentType.Date:
            return {
              __typename: 'SubmissionFilenameDateComponent',
              dateFormat: SubmissionFilenameDateFormat.Yyyymmdd
            }
          case SubmissionFilenameComponentType.Submitter:
            return {
              __typename: 'SubmissionFilenameSubmitterComponent',
              submitterProperty: SubmissionFilenameSubmitterProperty.Name
            }
        }
      }

      onAdd(getItem())
    },
    [onAdd]
  )

  return (
    <Menu>
      <MenuButton>
        <FilterButton>
          <FilterButtonLabel>
            <PlusIcon size='small' mr={2} />
            {t('settings.exports.filenameFormatField.addComponent')}
          </FilterButtonLabel>
        </FilterButton>
      </MenuButton>
      <MenuContent gutter={4} aria-label='filters'>
        <Suspense
          fallback={
            <Flex py={4} alignItems='center' justifyContent='center'>
              <Spinner size='small' />
            </Flex>
          }
        >
          {pipe(
            componentTypes,
            A.map((componentType) => {
              if (
                componentType.value ===
                SubmissionFilenameComponentType.FormField
              ) {
                return (
                  <SelectFieldModal
                    key={componentType.value}
                    fields={fields}
                    selectedKey={O.none}
                    onSelect={(field) =>
                      onAdd({
                        __typename: 'SubmissionFilenameFormFieldComponent',
                        fieldKey: field.key
                      })
                    }
                  >
                    <MenuItem hideOnClick={false}>
                      {componentType.label}
                    </MenuItem>
                  </SelectFieldModal>
                )
              }

              return (
                <MenuItem
                  key={componentType.value}
                  onClick={() => handleMenuItemClick(componentType.value)}
                >
                  {componentType.label}
                </MenuItem>
              )
            })
          )}
        </Suspense>
      </MenuContent>
    </Menu>
  )
}
