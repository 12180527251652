/* eslint-disable max-lines */
/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable complexity */

import { constant, pipe } from 'fp-ts/function'
import * as R from 'fp-ts/Reader'
import * as O from 'fp-ts/Option'
import * as boolean from 'fp-ts/boolean'
import { Endomorphism } from 'fp-ts/Endomorphism'
import * as L from 'monocle-ts/Lens'
import * as S from '@woorcs/types/Schemable'
import { uuid, UUID } from '@woorcs/types/UUID'
import { Location, Email, DateRange } from '@woorcs/types'

import { TranslateableText } from '../i18n/TranslateableText'

import * as ElementRule from './ElementRule'
import { ElementRuleEngine } from './RuleEngine'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export interface FormElement {
  type: string
  id: UUID
  rule: ElementRule.ElementRule | null
  // [key: string]: unknown
}

export const FormElement = S.type((S) =>
  S.struct({
    type: S.string,
    id: UUID.schema(S),
    rule: S.nullable(ElementRule.ElementRule.schema(S))
  })
)

export interface FormParentElement extends FormElement {
  children: FormElement[]
}

export interface FormInputElement<T = unknown> extends FormElement {
  key: UUID
  label: TranslateableText
  informativeText: TranslateableText
  defaultValue: T | null
  optional: boolean
}

export interface WithPlaceholder {
  placeholder: TranslateableText
}

export interface WithResponseSet {
  responseSet: UUID
}

export interface WithInformativeText {
  informativeText: TranslateableText
}

const rule = S.make((S) => S.nullable(ElementRule.ElementRule.schema(S)))

// -------------------------------------------------------------------------------------
// layout elements
// -------------------------------------------------------------------------------------

export interface FieldsetElement extends FormElement {
  type: 'Fieldset'
  children: InputElementType[]
}

export const FieldsetElement: S.Type<FieldsetElement> = S.type((S) =>
  S.lazy('Fieldset', () =>
    S.struct({
      type: S.literal('Fieldset'),
      id: UUID.schema(S),
      children: S.array(InputElementType.schema(S)),
      rule: rule(S)
    })
  )
)

// -------------------------------------------------------------------------------------
// static elements
// -------------------------------------------------------------------------------------

export interface TextElement extends FormElement {
  type: 'Text'
  text: TranslateableText
}

export const TextElement: S.Type<TextElement> = S.type((S) =>
  S.struct({
    type: S.literal('Text'),
    id: UUID.schema(S),
    rule: rule(S),
    text: TranslateableText.schema(S)
  })
)

export interface HeadingElement extends FormElement {
  type: 'Heading'
  text: TranslateableText
}

export const HeadingElement: S.Type<HeadingElement> = S.type((S) =>
  S.struct({
    type: S.literal('Heading'),
    id: UUID.schema(S),
    rule: rule(S),
    text: TranslateableText.schema(S)
  })
)

export const ContentElement = S.sum('type')({
  Text: (S) => TextElement.schema(S),
  Heading: (S) => HeadingElement.schema(S)
})

export type ContentElement = S.TypeOf<typeof ContentElement>

const StaticElementOptions = S.type((S) =>
  S.partial({
    includeInReport: S.boolean
  })
)

type StaticElementOptions = S.TypeOf<typeof StaticElementOptions>

export interface AlertElement extends FormElement {
  type: 'Alert'
  children: TextElement[]
  status: 'neutral' | 'danger' | 'warning' | 'info'
  options?: StaticElementOptions
}

export const AlertElementStatus = S.type((S) =>
  S.literal('neutral', 'danger', 'warning', 'info')
)

export type AlertElementStatus = S.TypeOf<typeof AlertElementStatus>

export const AlertElement: S.Type<AlertElement> = S.type((S) =>
  pipe(
    S.struct({
      type: S.literal('Alert'),
      id: UUID.schema(S),
      rule: rule(S),
      children: S.array(TextElement.schema(S)),
      status: AlertElementStatus.schema(S)
    }),
    S.intersect(
      S.partial({
        options: StaticElementOptions.schema(S)
      })
    )
  )
)

// -------------------------------------------------------------------------------------
// input elements
// -------------------------------------------------------------------------------------

export const TextInputElementOptions = S.type((S) =>
  S.struct({
    multiline: S.boolean,
    maxLength: S.nullable(S.number),
    minLength: S.nullable(S.number),
    pattern: S.nullable(S.string)
  })
)

export type TextInputElementOptions = S.TypeOf<typeof TextInputElementOptions>

export interface TextInputElement
  extends FormInputElement<string>,
    WithPlaceholder {
  type: 'TextInput'
  options: TextInputElementOptions
}

export const TextInputElement: S.Type<TextInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('TextInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(S.string),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    placeholder: TranslateableText.schema(S),
    options: TextInputElementOptions.schema(S),
    optional: S.boolean
  })
)

export const NumberInputElementOptions = S.type((S) =>
  S.struct({
    max: S.nullable(S.number),
    min: S.nullable(S.number)
  })
)

export type NumberInputElementOptions = S.TypeOf<
  typeof NumberInputElementOptions
>

export interface NumberInputElement
  extends FormInputElement<number>,
    WithPlaceholder {
  type: 'NumberInput'
  options: NumberInputElementOptions
}

export const NumberInputElement: S.Type<NumberInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('NumberInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(S.number),
    label: TranslateableText.schema(S),
    placeholder: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    options: NumberInputElementOptions.schema(S),
    optional: S.boolean
  })
)

export interface EmailInputElement
  extends FormInputElement<Email>,
    WithPlaceholder {
  type: 'EmailInput'
}

export const EmailInputElement: S.Type<EmailInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('EmailInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(Email.schema(S)),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    placeholder: TranslateableText.schema(S),
    optional: S.boolean
  })
)

export interface LocationInputElement extends FormInputElement<Location> {
  type: 'LocationInput'
}

export const LocationInputElement: S.Type<LocationInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('LocationInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(Location.schema(S)),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    optional: S.boolean
  })
)

export interface SignatureInputElement extends FormInputElement<string> {
  type: 'SignatureInput'
  options: {
    allowUpload: boolean
  }
}

export const SignatureInputElement: S.Type<SignatureInputElement> = S.type(
  (S) =>
    S.struct({
      type: S.literal('SignatureInput'),
      id: UUID.schema(S),
      key: UUID.schema(S),
      rule: rule(S),
      defaultValue: S.nullable(S.string),
      label: TranslateableText.schema(S),
      informativeText: TranslateableText.schema(S),
      options: S.struct({
        allowUpload: S.boolean
      }),
      optional: S.boolean
    })
)

export interface ImageInputElement extends FormInputElement<string[]> {
  type: 'ImageInput'
  options: {
    multiple: boolean
  }
}

export const ImageInputElement: S.Type<ImageInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('ImageInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(S.array(S.string)),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    options: S.struct({
      multiple: S.boolean
    }),
    optional: S.boolean
  })
)

export interface DateInputElement
  extends FormInputElement<string>,
    WithPlaceholder {
  type: 'DateInput'
  options: {
    withTime: boolean
  }
}

export const DateInputElement: S.Type<DateInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('DateInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(S.string),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    placeholder: TranslateableText.schema(S),
    options: S.struct({
      withTime: S.boolean
    }),
    optional: S.boolean
  })
)

export interface DateRangeInputElement extends FormInputElement<DateRange> {
  type: 'DateRangeInput'
  options: {
    withTime: boolean
    allowWeekends: boolean
  }
}

export const DateRangeInputElement: S.Type<DateRangeInputElement> = S.type(
  (S) =>
    S.struct({
      type: S.literal('DateRangeInput'),
      id: UUID.schema(S),
      key: UUID.schema(S),
      rule: rule(S),
      defaultValue: S.nullable(DateRange.schema(S)),
      label: TranslateableText.schema(S),
      informativeText: TranslateableText.schema(S),
      options: S.struct({
        withTime: S.boolean,
        allowWeekends: S.boolean
      }),
      optional: S.boolean
    })
)

export interface TimeInputElement
  extends FormInputElement<string>,
    WithPlaceholder {
  type: 'TimeInput'
}

export const TimeInputElement: S.Type<TimeInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('TimeInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(S.string),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    placeholder: TranslateableText.schema(S),
    optional: S.boolean
  })
)

export interface SelectInputElement
  extends FormInputElement<string>,
    WithResponseSet {
  type: 'SelectInput'
}

export const SelectInputElement: S.Type<SelectInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('SelectInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(S.string),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    responseSet: UUID.schema(S),
    optional: S.boolean
  })
)

export interface MultiSelectInputElement
  extends FormInputElement<string[]>,
    WithResponseSet {
  type: 'MultiSelectInput'
}

export const MultiSelectInputElement: S.Type<MultiSelectInputElement> = S.type(
  (S) =>
    S.struct({
      type: S.literal('MultiSelectInput'),
      id: UUID.schema(S),
      key: UUID.schema(S),
      rule: rule(S),
      defaultValue: S.nullable(S.array(S.string)),
      label: TranslateableText.schema(S),
      informativeText: TranslateableText.schema(S),
      responseSet: UUID.schema(S),
      optional: S.boolean
    })
)

export interface GroupInputElement
  extends FormInputElement<Record<string, unknown>> {
  type: 'GroupInput'
  document: UUID
}

export const GroupInputElement: S.Type<GroupInputElement> = S.type((S) =>
  S.struct({
    type: S.literal('GroupInput'),
    id: UUID.schema(S),
    key: UUID.schema(S),
    rule: rule(S),
    defaultValue: S.nullable(S.record(S.unknown)),
    label: TranslateableText.schema(S),
    informativeText: TranslateableText.schema(S),
    document: UUID.schema(S),
    optional: S.boolean
  })
)

// -------------------------------------------------------------------------------------
// adts
// -------------------------------------------------------------------------------------

export const LayoutElementType = S.sum('type')({
  Fieldset: (S) => FieldsetElement.schema(S)
})

export type LayoutElementType = S.TypeOf<typeof LayoutElementType>

export const StaticElementType = S.sum('type')({
  Text: (S) => TextElement.schema(S),
  Alert: (S) => AlertElement.schema(S)
})

export type StaticElementType = S.TypeOf<typeof StaticElementType>

export const InputElementType = S.sum('type')({
  TextInput: (S) => TextInputElement.schema(S),
  NumberInput: (S) => NumberInputElement.schema(S),
  EmailInput: (S) => EmailInputElement.schema(S),
  LocationInput: (S) => LocationInputElement.schema(S),
  SignatureInput: (S) => SignatureInputElement.schema(S),
  ImageInput: (S) => ImageInputElement.schema(S),
  DateInput: (S) => DateInputElement.schema(S),
  DateRangeInput: (S) => DateRangeInputElement.schema(S),
  TimeInput: (S) => TimeInputElement.schema(S),
  SelectInput: (S) => SelectInputElement.schema(S),
  MultiSelectInput: (S) => MultiSelectInputElement.schema(S),
  GroupInput: (S) => GroupInputElement.schema(S)
})

export type InputElementType = S.TypeOf<typeof InputElementType>

export const FormElementType = S.sum('type')({
  /**
   * layout
   */
  Fieldset: (S) => FieldsetElement.schema(S),

  /**
   * static
   */
  Text: (S) => TextElement.schema(S),
  Alert: (S) => AlertElement.schema(S),

  /**
   * inputs
   */
  TextInput: (S) => TextInputElement.schema(S),
  NumberInput: (S) => NumberInputElement.schema(S),
  EmailInput: (S) => EmailInputElement.schema(S),
  LocationInput: (S) => LocationInputElement.schema(S),
  SignatureInput: (S) => SignatureInputElement.schema(S),
  ImageInput: (S) => ImageInputElement.schema(S),
  DateInput: (S) => DateInputElement.schema(S),
  DateRangeInput: (S) => DateRangeInputElement.schema(S),
  TimeInput: (S) => TimeInputElement.schema(S),
  SelectInput: (S) => SelectInputElement.schema(S),
  MultiSelectInput: (S) => MultiSelectInputElement.schema(S),
  GroupInput: (S) => GroupInputElement.schema(S)
})

export type FormElementType = S.TypeOf<typeof FormElementType>

export type FormElementTypeName = FormElementType['type']

export const PlaceholderInputElement = FormElementType.select([
  'TextInput',
  'DateInput',
  'NumberInput',
  'EmailInput',
  'TimeInput'
])

export type PlaceholderInputElement = S.TypeOf<typeof PlaceholderInputElement>

export const ResponseSetInputElement = FormElementType.select([
  'SelectInput',
  'MultiSelectInput'
])

export type ResponseSetInputElement = S.TypeOf<typeof ResponseSetInputElement>

export const FileElementType = S.sum('type')({
  SignatureInput: (S) => SignatureInputElement.schema(S),
  ImageInput: (S) => ImageInputElement.schema(S)
})

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const textInputElement = (
  props: Omit<
    TextInputElement,
    'type' | 'id' | 'options' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<
      Pick<TextInputElement, 'options' | 'rule' | 'optional' | 'defaultValue'>
    >
) =>
  FormElementType.as.TextInput({
    id: uuid(),
    options: {
      multiline: false,
      maxLength: null,
      minLength: null,
      pattern: null
    },
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const numberInputElement = (
  props: Omit<
    NumberInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<NumberInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.NumberInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,

    ...props
  })

export const emailInputElement = (
  props: Omit<
    EmailInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<EmailInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.EmailInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const locationInputElement = (
  props: Omit<
    LocationInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<LocationInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.LocationInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const signatureInputElement = (
  props: Omit<
    SignatureInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<SignatureInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.SignatureInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const imageInputElement = (
  props: Omit<
    ImageInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<ImageInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.ImageInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const dateInputElement = (
  props: Omit<
    DateInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<DateInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.DateInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const dateRangeInputElement = (
  props: Omit<
    DateRangeInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<DateRangeInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.DateRangeInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const timeInputElement = (
  props: Omit<
    TimeInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<TimeInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.TimeInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const selectInputElement = (
  props: Omit<
    SelectInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<SelectInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.SelectInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const multiSelectInputElement = (
  props: Omit<
    MultiSelectInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<MultiSelectInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.MultiSelectInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const groupInputElement = (
  props: Omit<
    GroupInputElement,
    'type' | 'id' | 'rule' | 'optional' | 'defaultValue'
  > &
    Partial<Pick<GroupInputElement, 'rule' | 'optional' | 'defaultValue'>>
) =>
  FormElementType.as.GroupInput({
    id: uuid(),
    optional: false,
    rule: null,
    defaultValue: null,
    ...props
  })

export const fieldsetElement = (
  props: Omit<FieldsetElement, 'type' | 'id' | 'rule'> &
    Partial<Pick<FieldsetElement, 'rule'>>
) =>
  FormElementType.as.Fieldset({
    id: uuid(),
    rule: null,
    ...props
  })

export const textElement = (
  props: Omit<TextElement, 'type' | 'id' | 'rule'> &
    Partial<Pick<TextElement, 'rule'>>
) =>
  FormElementType.as.Text({
    id: uuid(),
    rule: null,
    ...props
  })

export const alertElement = (
  props: Omit<AlertElement, 'type' | 'id' | 'rule'> &
    Partial<Pick<AlertElement, 'rule'>>
) =>
  FormElementType.as.Alert({
    id: uuid(),
    rule: null,
    ...props
  })

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

const lens = L.id<FormElement>()
const ruleLens = pipe(lens, L.prop('rule'))

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export type ValueOfInputElement<F> =
  F extends FormInputElement<infer V> ? V : never

export const isLayoutElement = (e: FormElement): e is LayoutElementType =>
  LayoutElementType.is(e)

export const isStaticElement = (e: FormElement): e is StaticElementType =>
  StaticElementType.is(e)

export const isInputElement: (e: FormElement) => e is InputElementType =
  FormElementType.isAnyOf([
    'TextInput',
    'NumberInput',
    'EmailInput',
    'LocationInput',
    'SignatureInput',
    'ImageInput',
    'DateInput',
    'DateRangeInput',
    'TimeInput',
    'SelectInput',
    'MultiSelectInput',
    'GroupInput'
  ]) as any

export const isResponseSetInputElement = (
  e: FormElement
): e is ResponseSetInputElement => ResponseSetInputElement.is(e)

export const addRule = (rule: ElementRule.ElementRule) => ruleLens.set(rule)

export const removeRule = (element: FormElement) =>
  pipe(element, ruleLens.set(null))

export const updateRule = (rule: ElementRule.ElementRule) => addRule(rule)

export const toOptional: Endomorphism<InputElementType> = (element) => ({
  ...element,
  optional: true
})

// -------------------------------------------------------------------------------------
// instance
// -------------------------------------------------------------------------------------

export interface ElementEnvironment {
  ruleEngine: ElementRuleEngine
}

export const isElementVisible = (
  element: FormElement
): R.Reader<ElementEnvironment, boolean> =>
  pipe(
    element.rule,
    O.fromNullable,
    O.fold(constant(R.of<ElementEnvironment, boolean>(true)), (rule) =>
      pipe(
        R.ask<ElementEnvironment>(),
        R.map(({ ruleEngine }) =>
          pipe(
            pipe(ruleEngine.isFulfilled(rule), (fulfilled) =>
              pipe(
                rule,
                ElementRule.matchRule({
                  show: () => fulfilled,
                  hide: () => !fulfilled
                })
              )
            )
          )
        )
      )
    )
  )

export const renderElement =
  <E extends FormElement, A>(render: (element: E) => A) =>
  (element: E) =>
    pipe(
      isElementVisible(element),
      R.map(
        boolean.match(
          () => O.none,
          () => O.some(render(element))
        )
      )
    )
