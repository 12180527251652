import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { ListItemTitle, SelectableListItem, Text } from '@woorcs/design-system'
import * as Optional from 'monocle-ts/Optional'
import { pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import * as string from 'fp-ts/string'
import { useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'
import { useDebouncedCallback } from 'use-debounce'

import { SearchModal } from '../SearchModal'

import {
  SelectCustomerModalCustomerFragment,
  SelectCustomerModalQuery,
  SelectCustomerModalQueryDocument
} from './__generated__/SelectCustomerModal'

interface CustomerListItemProps {
  isSelected: boolean
  customer: SelectCustomerModalCustomerFragment
  onSelect(customer: SelectCustomerModalCustomerFragment): void
}

const CustomerListItem = ({
  isSelected,
  customer,
  onSelect
}: CustomerListItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(customer)
  }, [onSelect, customer])

  return (
    <SelectableListItem
      alignItems='center'
      isSelected={isSelected}
      dense
      onClick={handleClick}
    >
      <ListItemTitle>
        <Text>{customer.name}</Text>
      </ListItemTitle>
    </SelectableListItem>
  )
}

const responseOptional = pipe(
  Optional.id<SelectCustomerModalQuery>(),
  Optional.prop('customers')
)

interface SelectCustomerModalProps {
  searchPlaceholder?: string
  selectedId: O.Option<string>
  onSelect(customer: SelectCustomerModalCustomerFragment): void
  children: ReactElement
}

export const SelectCustomerModal = ({
  searchPlaceholder = 'Find customer',
  onSelect,
  selectedId,
  children
}: SelectCustomerModalProps) => {
  const isSelected = (customer: SelectCustomerModalCustomerFragment) =>
    O.getEq(string.Eq).equals(O.some(customer.id), selectedId)

  const [query, setQuery] = useState<string>('')
  const [debounced, setDebounced] = useState(query)
  const debounce = useDebouncedCallback((query: string) => {
    setDebounced(query)
  }, 200)

  useEffect(() => {
    debounce.callback(query)
  }, [debounce, query])

  const variables = useMemo(
    () => ({
      query: debounced,
      limit: 100
    }),
    [debounced]
  )

  return pipe(
    useQuery({
      query: SelectCustomerModalQueryDocument,
      variables
    }),
    queryToDatumEither(responseOptional),
    DE.squash(
      () => null,
      () => null,
      (page) => (
        <SearchModal
          items={page.data}
          query={query}
          searchPlaceholder={searchPlaceholder}
          renderItem={(customer) => (
            <CustomerListItem
              key={customer.id}
              customer={customer}
              isSelected={isSelected(customer)}
              onSelect={onSelect}
            />
          )}
          onQueryChange={setQuery}
        >
          {children}
        </SearchModal>
      )
    )
  )
}
