import React, { ChangeEvent, useCallback } from 'react'
import {
  Box,
  Card,
  FormField,
  Input,
  Subtitle,
  Textarea
} from '@woorcs/design-system'
import { some } from 'fp-ts/Option'
import { Locale, TranslateableText } from '@woorcs/form'
import { pipe } from 'fp-ts/function'

import { SelectLanguageInput } from '../SelectLanguageInput'
import { useEditorContext, useValue } from '../../state'

export const InspectionFormSettings = () => {
  const root = useValue()
  const { editor } = useEditorContext()

  const handleTitleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const title = e.target.value

      const updateTitle = editor.updateElement({
        ...root,
        title: TranslateableText.translateableText(title)
      })

      updateTitle()
    },
    [editor, root]
  )

  const handleDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const description = e.target.value

      const updateDescription = editor.updateElement({
        ...root,
        description: pipe(
          root.description,
          TranslateableText.setText(description)
        )
      })

      updateDescription()
    },
    [editor, root]
  )

  const handleDefaultLanguageChange = useCallback(
    (language: Locale.Locale) => {
      const updateDefaultLanguage = editor.updateElement({
        ...root,
        i18n: {
          ...root.i18n,
          defaultLanguage: language
        }
      })

      updateDefaultLanguage()
    },
    [editor, root]
  )

  return (
    <Box>
      <Subtitle mb={2}>General</Subtitle>
      <Card p={5} mb={8} border='base' elevation={0}>
        <FormField label='Name' mb={4}>
          <Input value={root?.title?.text ?? ''} onChange={handleTitleChange} />
        </FormField>
        {/* <FormField label='Description'>
          <Textarea
            value={root.description.text}
            onChange={handleDescriptionChange}
          />
        </FormField> */}
      </Card>

      <Subtitle mb={2}>Languages</Subtitle>

      <Card p={5} border='base' elevation={0}>
        <FormField
          label='Default language'
          infoMessage={`Note: changing the default language won't update the default translations.`}
        >
          <SelectLanguageInput
            value={some(root.i18n.defaultLanguage)}
            onChange={handleDefaultLanguageChange}
          />
        </FormField>
      </Card>
    </Box>
  )
}
