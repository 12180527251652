import { useCallback, useState, ReactElement } from 'react'
import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { useSnackbar } from '@woorcs/design-system'

import { CustomerForm, CustomerFormModal } from '../CustomerForm'

import { CreateCustomerDocument } from './__generated__/AddCustomerModal'

type AddCustomerModalProps = {
  children: ReactElement
}

export const AddCustomerModal = ({ children }: AddCustomerModalProps) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { showSnackbar } = useSnackbar()
  const [, addCustomer] = useMutation(CreateCustomerDocument)

  const handleSubmit = useCallback(
    (customer: CustomerForm, actions: FormikHelpers<CustomerForm>) => {
      addCustomer(
        {
          input: { ...customer }
        },
        {
          additionalTypenames: ['CustomerListPage']
        }
      )
        .then(() => {
          showSnackbar({
            title: t(
              'customers:addCustomerModal.addCustomerSuccessSnackbar.title'
            ),
            variant: 'success'
          })
          setVisible(false)
        })
        .catch(() => {
          showSnackbar({
            title: 'Failed to save customer',
            variant: 'danger'
          })
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [addCustomer, showSnackbar, t]
  )

  return (
    <CustomerFormModal
      title={t('customers:addCustomerModal.title')}
      visible={visible}
      onVisiblityChange={setVisible}
      onSubmit={handleSubmit}
    >
      {children}
    </CustomerFormModal>
  )
}
