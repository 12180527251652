/* eslint-disable max-lines */
import React, { ReactNode, useCallback, useEffect } from 'react'
import {
  Box,
  FormField,
  FormFieldLabel,
  Input,
  Listbox,
  ListboxOption,
  Text
} from '@woorcs/design-system'
import * as Lens from 'monocle-ts/Lens'
import * as O from 'fp-ts/Option'
import * as Optional from 'monocle-ts/Optional'
import * as RR from 'fp-ts/ReadonlyRecord'
import * as A from 'fp-ts/Array'
import {
  FormElement,
  ResponseSet,
  FormDocument,
  TranslateableText
} from '@woorcs/form'
import { constVoid, constant, pipe } from 'fp-ts/function'
import { UUID } from '@woorcs/types/UUID'
import { InspectionFormDefinition } from '@woorcs/inspection-form'

import { SelectResponseSet } from '../SelectResponseSet'
import { useEditorContext, useValue } from '../../state'
import { SelectResponseSetOption } from '../SelectResponseSetOption'
import { SelectDocument } from '../SelectDocument'

import {
  BooleanField,
  NumberField,
  TextField,
  TranslateableTextField,
  useElementField
} from './Field'
import { BlurInput } from './BlurInput'

// -------------------------------------------------------------------------------------
// fields
// -------------------------------------------------------------------------------------

type PlaceholderFieldProps = {
  element: FormElement.PlaceholderInputElement
}

const placeholderLens =
  FormElement.PlaceholderInputElement.lensFromProp('placeholder')

const PlaceholderField = ({ element }: PlaceholderFieldProps) => (
  <TranslateableTextField
    label='Placeholder'
    element={element}
    lens={placeholderLens}
  />
)

const inputElementLens = Lens.id<FormElement.FormInputElement>()

const informativeTextElementLens = Lens.id<
  FormElement.FormInputElement & FormElement.WithInformativeText
>()

const informativeTextLens = pipe(
  informativeTextElementLens,
  Lens.prop('informativeText')
)

const labelLens = pipe(inputElementLens, Lens.prop('label'))

interface LabelFieldProps {
  element: FormElement.FormInputElement
}

const LabelField = ({ element }: LabelFieldProps) => (
  <TranslateableTextField label='Label' element={element} lens={labelLens} />
)

const optionalLens = pipe(inputElementLens, Lens.prop('optional'))

interface OptionalFieldProps {
  element: FormElement.FormInputElement
}

const OptionalField = ({ element }: OptionalFieldProps) => (
  <BooleanField element={element} label='Optional' lens={optionalLens} />
)

interface InformativeTextFieldProps {
  element: FormElement.FormInputElement
}

const InformativeTextField = ({ element }: InformativeTextFieldProps) => (
  <TranslateableTextField
    label='Informative text'
    element={element}
    lens={informativeTextLens}
  />
)

interface ResponseSetFieldProps {
  element: FormElement.FormElement & FormElement.WithResponseSet
}

const ResponseSetField = ({ element }: ResponseSetFieldProps) => {
  const { editor } = useEditorContext()
  const root = useValue()

  const handleResponseSetChange = useCallback(
    (responseSet: ResponseSet.ResponseSet) => {
      const updateRequired = editor.updateElement({
        ...element,
        responseSet: responseSet.id
      })

      updateRequired()
    },
    [editor, element]
  )

  return (
    <Box mb={4}>
      <FormFieldLabel mb={2}>Response set</FormFieldLabel>
      <SelectResponseSet
        value={root.responseSets[element.responseSet]}
        onSelect={handleResponseSetChange}
      />
    </Box>
  )
}

interface DocumentFieldProps {
  element: FormElement.GroupInputElement
}

const DocumentField = ({ element }: DocumentFieldProps) => {
  const { editor } = useEditorContext()
  const root = useValue()
  const value = pipe(
    O.fromNullable(element.document),
    O.chain((id) => pipe(root, InspectionFormDefinition.getFormDocument(id)))
  )

  const handleDocumentChange = useCallback(
    (document: O.Option<FormDocument.FormDocument>) => {
      const updateDocument = pipe(
        document,
        O.fold(
          () => null,
          (document) => document.id
        ),
        (value) =>
          editor.updateElement({
            ...element,
            document: value
          })
      )

      updateDocument()
    },
    [editor, element]
  )

  return (
    <Box mb={4}>
      <FormFieldLabel mb={2}>Document</FormFieldLabel>
      <SelectDocument
        value={value}
        placeholder='Select a document'
        onChange={handleDocumentChange}
      />
    </Box>
  )
}

interface FieldSectionProps {
  title: string
  children: ReactNode
  isLast?: boolean
}

const FieldSection = ({
  title,
  children,
  isLast = false
}: FieldSectionProps) => (
  <Box mb={4} borderBottom={isLast ? 'none' : 'base'}>
    <Text as='p' fontWeight='bold' color='grey.400' mb={3}>
      {title}
    </Text>
    {children}
  </Box>
)

// -------------------------------------------------------------------------------------
// element forms
// -------------------------------------------------------------------------------------

interface ElementFormProps<
  T extends FormElement.FormElementType = FormElement.FormElementType
> {
  element: T
}

const getDefaultValueLens = <T extends FormElement.FormInputElement>() =>
  pipe(Lens.id<T>(), Lens.prop('defaultValue'))

const textInputElementOptionsLens = pipe(
  Lens.id<FormElement.TextInputElement>(),
  Lens.prop('options')
)

const multilineLens = pipe(textInputElementOptionsLens, Lens.prop('multiline'))
const maxLengthLens = pipe(textInputElementOptionsLens, Lens.prop('maxLength'))
const minLengthLens = pipe(textInputElementOptionsLens, Lens.prop('minLength'))

const textInputElementDefaultValueLens =
  getDefaultValueLens<FormElement.TextInputElement>()

const TextInputElementForm = ({
  element
}: ElementFormProps<FormElement.TextInputElement>) => (
  <Box>
    <FieldSection title='Basic'>
      <LabelField element={element} />
      <PlaceholderField element={element} />
      <TextField
        label='Default value'
        element={element}
        lens={textInputElementDefaultValueLens}
      />
      <InformativeTextField element={element} />
      <BooleanField label='Multiline' element={element} lens={multilineLens} />
    </FieldSection>
    <FieldSection title='Validation'>
      <OptionalField element={element} />
      <NumberField label='Max length' element={element} lens={maxLengthLens} />
      <NumberField label='Min length' element={element} lens={minLengthLens} />
    </FieldSection>
  </Box>
)

const numberInputElementOptionsLens = pipe(
  Lens.id<FormElement.NumberInputElement>(),
  Lens.prop('options')
)

const maxLens = pipe(numberInputElementOptionsLens, Lens.prop('max'))
const minLens = pipe(numberInputElementOptionsLens, Lens.prop('min'))

const NumberInputElementForm = ({
  element
}: ElementFormProps<FormElement.NumberInputElement>) => (
  <Box>
    <FieldSection title='Basic'>
      <LabelField element={element} />
      <PlaceholderField element={element} />
      <InformativeTextField element={element} />
    </FieldSection>
    <FieldSection title='Validation'>
      <OptionalField element={element} />
      <NumberField label='Max' element={element} lens={maxLens} />
      <NumberField label='Min' element={element} lens={minLens} />
    </FieldSection>
  </Box>
)

const selectInputElementDefaultValueLens =
  getDefaultValueLens<FormElement.SelectInputElement>()

const SelectInputDefaultValueField = ({
  element
}: ElementFormProps<FormElement.SelectInputElement>) => {
  const root = useValue()
  const responseSet = pipe(
    O.fromNullable(element.responseSet),
    O.chain((id) => pipe(root.responseSets, RR.lookup(id)))
  )
  const [value, setValue] = useElementField(
    element,
    selectInputElementDefaultValueLens
  )

  const handleChange = useCallback(
    (id: O.Option<UUID>) => {
      pipe(O.toNullable(id), setValue)
    },
    [setValue]
  )

  useEffect(() => {
    pipe(
      O.fromNullable(value),
      O.fold(constVoid, (value) =>
        pipe(
          responseSet,
          O.fold(
            () => setValue(null),
            (responseSet) =>
              pipe(
                responseSet,
                ResponseSet.getOption(value as UUID),
                O.fold(() => setValue(null), constVoid)
              )
          )
        )
      )
    )
  }, [responseSet, setValue, value])

  return (
    <FormField label='Default value' mb={4}>
      {pipe(
        responseSet,
        O.fold(
          () => <Input disabled />,
          (responseSet) => (
            <SelectResponseSetOption
              value={O.fromNullable(value as UUID | null)}
              placeholder='Select a default value'
              responseSet={responseSet}
              onChange={handleChange}
            />
          )
        )
      )}
    </FormField>
  )
}

const SelectInputElementForm = ({
  element
}: ElementFormProps<FormElement.SelectInputElement>) => {
  return (
    <Box>
      <FieldSection title='Basic'>
        <LabelField element={element} />
        <ResponseSetField element={element} />
        <SelectInputDefaultValueField element={element} />
        <InformativeTextField element={element} />
      </FieldSection>
      <FieldSection title='Validation'>
        <OptionalField element={element} />
      </FieldSection>
    </Box>
  )
}

const GroupInputElementForm = ({
  element
}: ElementFormProps<FormElement.GroupInputElement>) => {
  return (
    <Box>
      <FieldSection title='Basic'>
        <LabelField element={element} />
        <DocumentField element={element} />
        <InformativeTextField element={element} />
      </FieldSection>
      <FieldSection title='Validation'>
        <OptionalField element={element} />
      </FieldSection>
    </Box>
  )
}

// const multiSelectInputElementDefaultValueLens =
//   getDefaultValueLens<FormElement.MultiSelectInputElement>()

// const MultiSelectInputDefaultValueField = ({
//   element
// }: ElementFormProps<FormElement.MultiSelectInputElement>) => {
//   const root = useValue()
//   const responseSet = pipe(
//     O.fromNullable(element.responseSet),
//     O.chain((id) => pipe(root.responseSets, RR.lookup(id)))
//   )
//   const [value, setValue] = useElementField(
//     element,
//     multiSelectInputElementDefaultValueLens
//   )

//   const handleChange = useCallback(
//     (id: O.Option<UUID>) => {
//       pipe([O.toNullable(id)], setValue)
//     },
//     [setValue]
//   )

//   return (
//     <FormField label='Default value' mb={4}>
//       {pipe(
//         responseSet,
//         O.fold(
//           () => <Input disabled />,
//           (responseSet) => (
//             <SelectResponseSetOption
//               value={O.fromNullable(value as UUID | null)}
//               placeholder='Select a default value'
//               responseSet={responseSet}
//               onChange={handleChange}
//             />
//           )
//         )
//       )}
//     </FormField>
//   )
// }

const MultiSelectInputElementForm = ({
  element
}: ElementFormProps<FormElement.MultiSelectInputElement>) => {
  return (
    <Box>
      <FieldSection title='Basic'>
        <LabelField element={element} />
        <ResponseSetField element={element} />
        <InformativeTextField element={element} />
        {/* <MultiSelectInputDefaultValueField element={element} /> */}
      </FieldSection>
      <FieldSection title='Validation'>
        <OptionalField element={element} />
      </FieldSection>
    </Box>
  )
}

const DefaultInputElementForm = ({
  element
}: ElementFormProps<FormElement.InputElementType>) => (
  <Box>
    <FieldSection title='Basic'>
      <LabelField element={element} />
      {FormElement.PlaceholderInputElement.is(element) && (
        <PlaceholderField element={element} />
      )}
      <InformativeTextField element={element} />
    </FieldSection>
    <FieldSection title='Validation'>
      <OptionalField element={element} />
    </FieldSection>
  </Box>
)

const alertElementChildrenLens = pipe(
  Lens.id<FormElement.AlertElement>(),
  Lens.prop('children')
)

const alertElementStatusLens = pipe(
  Lens.id<FormElement.AlertElement>(),
  Lens.prop('status')
)

const includeInReportLens: Lens.Lens<FormElement.AlertElement, boolean> = {
  set: (includeInReport) => (element) => ({
    ...element,
    options: {
      ...(element.options ?? {}),
      includeInReport
    }
  }),
  get: (element) => element.options?.includeInReport ?? false
}

interface AlertTextFieldProps {
  element: FormElement.AlertElement
}

export const AlertTextField = ({ element }: AlertTextFieldProps) => {
  const { editor } = useEditorContext()
  const value = pipe(
    A.head(element.children),
    O.map((element) => element.text.text),
    O.getOrElse(constant(''))
  )

  const handleChange = useCallback(
    (value: string) => {
      const update = pipe(
        element,
        alertElementChildrenLens.set([
          FormElement.textElement({
            text: TranslateableText.translateableText(value)
          })
        ]),
        editor.updateElement
      )

      update()
    },
    [editor.updateElement, element]
  )

  return (
    <FormField label='Text' mb={4}>
      <BlurInput value={value} multiline onChange={handleChange} />
    </FormField>
  )
}

export const AlertStatusField = ({ element }: AlertTextFieldProps) => {
  const { editor } = useEditorContext()
  const items = ['neutral', 'danger', 'warning', 'info']
  const value = element.status

  const handleChange = useCallback(
    (value: FormElement.AlertElementStatus) => {
      const update = pipe(
        element,
        alertElementStatusLens.set(value),
        editor.updateElement
      )

      update()
    },
    [editor.updateElement, element]
  )

  return (
    <FormField label='Status' mb={4}>
      <Listbox value={value} onChange={handleChange}>
        {items.map((status) => (
          <ListboxOption key={status} value={status}>
            {status}
          </ListboxOption>
        ))}
      </Listbox>
    </FormField>
  )
}

const AlertElementForm = ({
  element
}: ElementFormProps<FormElement.AlertElement>) => (
  <Box>
    <FieldSection title='Basic'>
      <AlertStatusField element={element} />
      <AlertTextField element={element} />
    </FieldSection>
    <FieldSection title='Options' isLast>
      <BooleanField
        element={element}
        label='Include in report'
        lens={includeInReportLens}
      />
    </FieldSection>
  </Box>
)

interface WithTimeFieldProps {
  element: FormElement.DateInputElement | FormElement.DateRangeInputElement
}

const dateElementLens = Lens.id<
  FormElement.DateInputElement | FormElement.DateRangeInputElement
>()
const withTimeLens = pipe(
  dateElementLens,
  Lens.prop('options'),
  Lens.prop('withTime')
)

const WithTimeField = ({ element }: WithTimeFieldProps) => (
  <BooleanField element={element} label='With time' lens={withTimeLens} />
)

interface AllowWeekendsFieldProps {
  element: FormElement.DateRangeInputElement
}

const dateRangeElementLens = Lens.id<FormElement.DateRangeInputElement>()

const allowWeekendsLens = pipe(
  dateRangeElementLens,
  Lens.prop('options'),
  Lens.prop('allowWeekends')
)

const AllowWeekendsField = ({ element }: AllowWeekendsFieldProps) => (
  <BooleanField
    element={element}
    label='Allow weekends'
    lens={allowWeekendsLens}
  />
)

const DateInputElementForm = ({
  element
}: ElementFormProps<
  FormElement.DateInputElement | FormElement.DateRangeInputElement
>) => (
  <Box>
    <FieldSection title='Basic'>
      <LabelField element={element} />

      {FormElement.DateInputElement.is(element) && (
        <PlaceholderField element={element} />
      )}

      <InformativeTextField element={element} />
    </FieldSection>
    <FieldSection title='Validation'>
      <OptionalField element={element} />
    </FieldSection>

    <FieldSection title='Options'>
      <WithTimeField element={element} />
      {FormElement.DateRangeInputElement.is(element) && (
        <AllowWeekendsField element={element} />
      )}
    </FieldSection>
  </Box>
)

// -------------------------------------------------------------------------------------
// main
// -------------------------------------------------------------------------------------

export const InputElementForm = ({
  element
}: ElementFormProps<FormElement.InputElementType>) =>
  pipe(
    element,
    FormElement.InputElementType.matchStrict({
      TextInput: (element) => <TextInputElementForm element={element} />,
      NumberInput: (element) => <NumberInputElementForm element={element} />,
      EmailInput: () => <DefaultInputElementForm element={element} />,
      LocationInput: () => <DefaultInputElementForm element={element} />,
      SignatureInput: () => <DefaultInputElementForm element={element} />,
      ImageInput: () => <DefaultInputElementForm element={element} />,
      DateInput: (element) => <DateInputElementForm element={element} />,
      DateRangeInput: (element) => <DateInputElementForm element={element} />,
      TimeInput: () => <DefaultInputElementForm element={element} />,
      SelectInput: (element) => <SelectInputElementForm element={element} />,
      MultiSelectInput: (element) => (
        <MultiSelectInputElementForm element={element} />
      ),
      GroupInput: (element) => <GroupInputElementForm element={element} />
    })
  )

export const ElementForm = ({ element }: ElementFormProps) => {
  if (FormElement.isInputElement(element)) {
    return <InputElementForm element={element} />
  }

  if (FormElement.AlertElement.is(element)) {
    return <AlertElementForm element={element} />
  }

  return null
}
