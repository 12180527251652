import { forwardRef, ReactNode } from 'react'

import { Box } from '../../layout'
import { PropsOf, system } from '../../../system'
import { Text } from '../../Text'

import { FormFieldLabel } from './FormFieldLabel'
import { FormFieldMessages } from './FormFieldMessages'
import {
  FormFieldProvider,
  useFormFieldProvider,
  UseFormFieldProviderProps
} from './context'

export interface FormFieldProps
  extends UseFormFieldProviderProps,
    PropsOf<typeof system.div> {
  showMessages?: boolean
  label?: ReactNode
  description?: string | null
}

export const FormField = forwardRef<'div', FormFieldProps>(
  (
    {
      label,
      description,
      showMessages = true,
      children,
      id,
      disabled,
      readOnly,
      required,
      invalid,
      focused,
      errorMessage,
      infoMessage,
      warningMessage,
      ...other
    },
    ref
  ) => {
    const context = useFormFieldProvider({
      id,
      disabled,
      readOnly,
      required,
      invalid,
      focused,
      errorMessage,
      infoMessage,
      warningMessage
    })

    return (
      <FormFieldProvider value={context}>
        <Box ref={ref} role='group' {...other}>
          {label && (
            <FormFieldLabel description={description}>{label}</FormFieldLabel>
          )}

          {children}

          {showMessages && <FormFieldMessages />}
        </Box>
      </FormFieldProvider>
    )
  }
)
