import {
  Box,
  Flex,
  H3,
  PrimaryButton,
  SecondaryButton,
  Tab,
  TabList
} from '@woorcs/design-system'
import { Link } from 'react-router-dom'
import { PreviewFormModal } from '@woorcs/inspection-form-editor'
import { useTranslation } from 'react-i18next'

import { FormVersionBadge, ListPageHeader } from '@app/components'

import { FormDetailsPageFormFragment } from './__generated__/FormDetails'

interface FormDetailsPageHeaderrProps {
  form: FormDetailsPageFormFragment
}

export const FormDetailsPageHeader = ({
  form
}: FormDetailsPageHeaderrProps) => {
  const { t } = useTranslation('form-details')

  return (
    <ListPageHeader borderBottom='base' mb={0} pb={0}>
      <Box width='100%'>
        <Flex justifyContent='space-between' mb={8} width='100%'>
          <Box>
            <H3 mb={3}>{form.title}</H3>
            <FormVersionBadge form={form} />
          </Box>

          <Flex alignItems='center'>
            <PreviewFormModal
              backButtonLabel='Back'
              definition={form.latestRevision.definition}
            >
              <SecondaryButton mr={4}>{t('preview')}</SecondaryButton>
            </PreviewFormModal>

            <PrimaryButton
              as={Link}
              {...({ to: `/forms/${form.id}/edit` } as any)}
            >
              {t('edit')}
            </PrimaryButton>
          </Flex>
        </Flex>

        <TabList size='large' marginBottom='-1px'>
          <Tab id='submissions'>{t('tabs.submissions')}</Tab>
          <Tab id='revisions'>{t('tabs.revisions')}</Tab>
          <Tab id='languages'>{t('tabs.languages')}</Tab>
          <Tab id='settings'>{t('tabs.settings')}</Tab>
        </TabList>
      </Box>
    </ListPageHeader>
  )
}
