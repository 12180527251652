import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { space } from '@woorcs/utils'

import { CustomerRow } from '../CustomerRow'
import { CustomerListCustomerFragment } from '../__generated__/CustomerListFragments'

interface ListProps {
  customers: CustomerListCustomerFragment[]
  onRowRemove(customerId: string): void
}

export const List = ({ customers, onRowRemove }: ListProps) => {
  const { t } = useTranslation(['common'])

  return (
    <Table mb={5} outerPadding={space(14)}>
      <TableHead>
        <TableHeadCell>{t('common:name')}</TableHeadCell>
        <TableHeadCell />
      </TableHead>
      <TableBody>
        {customers.map((customer, index) => (
          <CustomerRow key={index} customer={customer} onRemove={onRowRemove} />
        ))}
      </TableBody>
    </Table>
  )
}
