import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type SubmissionFilenameFormFragment = { __typename: 'Form', id: string, filenameFormat: Array<{ __typename: 'SubmissionFilenameCustomerComponent', customerProperty: SchemaTypes.SubmissionFilenameCustomerProperty } | { __typename: 'SubmissionFilenameDateComponent', dateFormat: SchemaTypes.SubmissionFilenameDateFormat } | { __typename: 'SubmissionFilenameFormFieldComponent', fieldKey: string } | { __typename: 'SubmissionFilenameSubmitterComponent', submitterProperty: SchemaTypes.SubmissionFilenameSubmitterProperty }>, latestRevision: { __typename: 'FormRevision', id: string, definition: any } };

export type UpdateFormSubmissionFilenameMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateFormSubmissionFilenameFormatInput;
}>;


export type UpdateFormSubmissionFilenameMutation = { __typename: 'Mutation', updateFormSubmissionFilenameFormat: { __typename: 'FormNotFoundError', message: string } | { __typename: 'UpdateFormSubmissionFilenameFormatError', message: string } | { __typename: 'UpdateFormSubmissionFilenameFormatSuccess', form: { __typename: 'Form', id: string, filenameFormat: Array<{ __typename: 'SubmissionFilenameCustomerComponent', customerProperty: SchemaTypes.SubmissionFilenameCustomerProperty } | { __typename: 'SubmissionFilenameDateComponent', dateFormat: SchemaTypes.SubmissionFilenameDateFormat } | { __typename: 'SubmissionFilenameFormFieldComponent', fieldKey: string } | { __typename: 'SubmissionFilenameSubmitterComponent', submitterProperty: SchemaTypes.SubmissionFilenameSubmitterProperty }>, latestRevision: { __typename: 'FormRevision', id: string, definition: any } } } };

export const SubmissionFilenameFormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubmissionFilenameFormFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Form"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"filenameFormat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubmissionFilenameCustomerComponent"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customerProperty"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubmissionFilenameDateComponent"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dateFormat"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubmissionFilenameFormFieldComponent"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fieldKey"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubmissionFilenameSubmitterComponent"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submitterProperty"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"latestRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"definition"}}]}}]}}]} as unknown as DocumentNode<SubmissionFilenameFormFragment, unknown>;
export const UpdateFormSubmissionFilenameDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFormSubmissionFilename"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormSubmissionFilenameFormatInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFormSubmissionFilenameFormat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormSubmissionFilenameFormatSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SubmissionFilenameFormFragment"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormNotFoundError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormSubmissionFilenameFormatError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}},...SubmissionFilenameFormFragmentDoc.definitions]} as unknown as DocumentNode<UpdateFormSubmissionFilenameMutation, UpdateFormSubmissionFilenameMutationVariables>;