import { useCallback, useEffect, useState } from 'react'
import * as TO from 'fp-ts-contrib/TaskOption'
import { tenantGraphqlClient } from '@woorcs/graphql'
import { Client } from 'urql'
import { cacheExchange, KeyingConfig } from '@urql/exchange-graphcache'
import { devtoolsExchange } from '@urql/devtools'
import {
  CustomerListPage,
  introspectionSchema,
  SubmissionListPage
} from '@woorcs/graphql/schema'

import { useAuth } from '../auth'

import { updates } from './updates'

const envExchanges = import.meta.env.DEV ? [devtoolsExchange] : []

const graphqlUrl =
  import.meta.env.VITE_REACT_APP_GRAPHQL_ENDPOINT?.toString() || ''

const keys: KeyingConfig = {
  SubmissionListPage: (page: SubmissionListPage) =>
    `${page.__typename}${page.pageInfo.limit}${page.pageInfo.offset}`,
  CustomerListPage: (page: CustomerListPage) =>
    `${page.__typename}${page.pageInfo.limit}${page.pageInfo.offset}`
}

export const useCreateClient = (organizationId: string) => {
  const [client, setClient] = useState<Client | null>(null)
  const [, { getAccessTokenSilently, logout }] = useAuth()

  const getAccessToken = useCallback(
    () => TO.fromTask(getAccessTokenSilently),
    [getAccessTokenSilently]
  )

  useEffect(() => {
    const cache = cacheExchange({
      updates,
      // resolvers,
      keys,
      schema: introspectionSchema as any
    })

    setClient(
      tenantGraphqlClient({
        url: graphqlUrl,
        organizationId,
        getAccessToken,
        logout,
        exchanges: envExchanges,
        cache,
        suspense: true,
        onRequestError: (error) => {
          if (import.meta.env.DEV) {
            //eslint-disable-next-line no-console
            console.log('Graphql Request error', error)
          }
        }
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  return client
}
