import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CustomerListCustomerListPageFragmentDoc } from './CustomerListFragments';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type CustomerListQueryVariables = SchemaTypes.Exact<{
  limit?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']>;
  offset?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']>;
  query?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
}>;


export type CustomerListQuery = { __typename: 'Query', customers: { __typename: 'CustomerListPage', data: Array<{ __typename: 'Customer', id: string, name: string, createdAt: any, updatedAt: any }>, pageInfo: { __typename: 'PageInfo', totalCount: number, offset: number, limit: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const CustomerListQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CustomerListQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"defaultValue":{"kind":"IntValue","value":"20"}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"offset"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"defaultValue":{"kind":"IntValue","value":"0"}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"defaultValue":{"kind":"StringValue","value":"","block":false}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"Argument","name":{"kind":"Name","value":"offset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"offset"}}},{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CustomerListCustomerListPageFragment"}}]}}]}},...CustomerListCustomerListPageFragmentDoc.definitions]} as unknown as DocumentNode<CustomerListQuery, CustomerListQueryVariables>;