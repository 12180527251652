import { TableCell, TableRow, Text } from '@woorcs/design-system'
import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import { CustomerListCustomerFragment } from '../__generated__/CustomerListFragments'

import { CustomerRowMenu } from './Menu'

interface CustomerRowProps {
  customer: CustomerListCustomerFragment
  onRemove(id: string): void
}

export const CustomerRow = ({ customer, onRemove }: CustomerRowProps) => {
  const handleRemoveCustomer = useCallback(() => {
    onRemove(customer.id)
  }, [onRemove, customer.id])

  return (
    <TableRow>
      <TableCell>
        <NavLink to={`/customers/${customer.id}`}>
          <Text
            as='span'
            color='grey.900'
            __css={{
              ':hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {customer.name}
          </Text>
        </NavLink>
      </TableCell>

      <TableCell textAlign='right'>
        <CustomerRowMenu customer={customer} onRemove={handleRemoveCustomer} />
      </TableCell>
    </TableRow>
  )
}
