import * as O from 'fp-ts/Option'
import {
  Badge,
  CloseIcon,
  DocumentIcon,
  Flex,
  FormFieldLabel,
  Box,
  IconButton,
  Text,
  useSnackbar
} from '@woorcs/design-system'
import { useEffect, useMemo, useState } from 'react'
import {
  SubmissionFilenameComponent,
  SubmissionFilenameComponentType
} from '@woorcs/graphql/schema'
import { FormElement } from '@woorcs/form'
import * as ElementTree from '@woorcs/types/ElementTree'
import { useMutation } from 'urql'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { SelectFilenameComponentMenu } from './SelectFilenameComponentMenu'
import {
  SubmissionFilenameFormFragment,
  UpdateFormSubmissionFilenameDocument
} from './__generated__/SubmissionFilenameForm'
import { Field, SelectFieldModal } from './SelectFieldModal'

const validElementTypes = [
  'TextInput',
  'DateInput',
  'TimeInput',
  'NumberInput',
  'EmailInput'
]

const getFormFields = (form: SubmissionFilenameFormFragment) => {
  return ElementTree.toArray(
    form.latestRevision.definition as unknown as ElementTree.ElementTree
  )
    .filter(FormElement.InputElementType.is)
    .filter((element) => validElementTypes.includes(element.type))
    .map(
      (element): Field => ({
        key: element.key.toString(),
        type: element.type as
          | 'TextInput'
          | 'DateInput'
          | 'TimeInput'
          | 'NumberInput'
          | 'EmailInput',
        label: element.label.text
      })
    )
}

interface SubmissionFilenameFormProps {
  form: SubmissionFilenameFormFragment
}

export const SubmissionFilenameForm = ({
  form
}: SubmissionFilenameFormProps) => {
  const { t } = useTranslation('form-details')
  const [filenameComponents, setFilenameComponents] = useState<
    SubmissionFilenameComponent[]
  >(form.filenameFormat)
  const formFields = useMemo(() => getFormFields(form), [form])
  const [, updateFormSubmissionFilename] = useMutation(
    UpdateFormSubmissionFilenameDocument
  )
  const { showSnackbar } = useSnackbar()
  const debouncedSubmit = useDebouncedCallback(
    (filenameComponents: SubmissionFilenameComponent[]) => {
      const format = filenameComponents.map((component) => {
        switch (component.__typename) {
          case 'SubmissionFilenameCustomerComponent':
            return {
              type: SubmissionFilenameComponentType.Customer,
              customer: {
                customerProperty: component.customerProperty
              },
              date: null,
              formField: null,
              submitter: null
            }
          case 'SubmissionFilenameDateComponent':
            return {
              type: SubmissionFilenameComponentType.Date,
              date: {
                dateFormat: component.dateFormat
              },
              customer: null,
              formField: null,
              submitter: null
            }
          case 'SubmissionFilenameFormFieldComponent':
            return {
              type: SubmissionFilenameComponentType.FormField,
              formField: {
                fieldKey: component.fieldKey
              },
              customer: null,
              date: null,
              submitter: null
            }
          case 'SubmissionFilenameSubmitterComponent':
            return {
              type: SubmissionFilenameComponentType.Submitter,
              submitter: {
                submitterProperty: component.submitterProperty
              },
              customer: null,
              date: null,
              formField: null
            }
        }
      })

      updateFormSubmissionFilename({
        input: {
          formId: form.id,
          format
        }
      })
        // .then(() => {
        //   showSnackbar({
        //     variant: 'success',
        //     title: t('settings.submissions.filename.updateSuccess')
        //   })
        // })
        .catch(() => {
          showSnackbar({
            title: t('settings.exports.filenameFormatField.updateError'),
            variant: 'danger'
          })
        })
    },
    300
  )

  useEffect(() => {
    debouncedSubmit.callback(filenameComponents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filenameComponents])

  const getComponentExampleLabel = (component: SubmissionFilenameComponent) => {
    switch (component.__typename) {
      case 'SubmissionFilenameCustomerComponent':
        return `[${t('settings.exports.filenameFormatField.componentLabels.customer')}]`
      case 'SubmissionFilenameDateComponent':
        const dateExample = format(new Date(), 'yyyy-MM-dd')

        return `[${dateExample}]`
      case 'SubmissionFilenameSubmitterComponent':
        return `[${t('settings.exports.filenameFormatField.componentLabels.submitter')}]`
      case 'SubmissionFilenameFormFieldComponent': {
        const formField = formFields.find(
          (field) => field.key === component.fieldKey
        )

        return formField ? `[${formField.label}]` : '[Form field]'
      }
    }
  }
  const getComponentLabel = (component: SubmissionFilenameComponent) => {
    switch (component.__typename) {
      case 'SubmissionFilenameCustomerComponent':
        return t(
          'settings.exports.filenameFormatField.componentLabels.customer'
        )
      case 'SubmissionFilenameDateComponent':
        return t('settings.exports.filenameFormatField.componentLabels.date')
      case 'SubmissionFilenameSubmitterComponent':
        return t(
          'settings.exports.filenameFormatField.componentLabels.submitter'
        )
      case 'SubmissionFilenameFormFieldComponent':
        const formField = formFields.find(
          (field) => field.key === component.fieldKey
        )

        return formField?.label
    }
  }
  const filenameExample =
    filenameComponents.length === 0
      ? '[UUID].pdf'
      : `${filenameComponents.map(getComponentExampleLabel).join('_')}.pdf`

  return (
    <Box>
      <FormFieldLabel
        description={t('settings.exports.filenameFormatField.description')}
      >
        {t('settings.exports.filenameFormatField.label')}
      </FormFieldLabel>

      <Flex flexDirection='column' alignItems='flex-start' gap={4}>
        <Flex pt={4} gap={2} flexWrap='wrap'>
          <SelectFilenameComponentMenu
            fields={formFields}
            onAdd={(component) =>
              setFilenameComponents([...filenameComponents, component])
            }
          />

          {filenameComponents.map((component, index) => {
            const label = getComponentLabel(component)

            if (
              component.__typename === 'SubmissionFilenameFormFieldComponent'
            ) {
              const formField = formFields.find(
                (field) => field.key === component.fieldKey
              )

              return (
                <SelectFieldModal
                  key={component.fieldKey}
                  fields={formFields}
                  selectedKey={O.some(component.fieldKey)}
                  onSelect={(field) => {
                    setFilenameComponents(
                      filenameComponents.map((c, i) =>
                        i === index ? { ...c, fieldKey: field.key } : c
                      )
                    )
                  }}
                >
                  <Badge key={index} style={{ cursor: 'pointer' }} pl={3}>
                    <Text>{formField?.label}</Text>
                    <IconButton
                      variant='plain'
                      size='mini'
                      ml={2}
                      onClick={() =>
                        setFilenameComponents(
                          filenameComponents.filter((_, i) => i !== index)
                        )
                      }
                    >
                      <CloseIcon size='small' />
                    </IconButton>
                  </Badge>
                </SelectFieldModal>
              )
            }

            return (
              <Badge key={index} pl={3}>
                <Text>{label}</Text>
                <IconButton
                  variant='plain'
                  size='mini'
                  ml={2}
                  onClick={() =>
                    setFilenameComponents(
                      filenameComponents.filter((_, i) => i !== index)
                    )
                  }
                >
                  <CloseIcon size='small' />
                </IconButton>
              </Badge>
            )
          })}
        </Flex>

        <Box width='100%'>
          <FormFieldLabel>
            {t('settings.exports.filenameFormatField.preview')}:
          </FormFieldLabel>
          <Flex
            width='100%'
            p={3}
            border='1px solid'
            borderColor='grey.200'
            alignItems='center'
            gap={3}
          >
            <DocumentIcon />
            <Text fontSize='small'>{filenameExample}</Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
