import { ReactElement } from 'react'
import * as t from 'io-ts'
import { Formik, FormikHelpers, Form } from 'formik'
import {
  Input,
  Modal,
  ModalFooter,
  ModalDialog,
  ModalDisclosure,
  SecondaryButton,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalDismiss
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { getFormikValidator, NonEmptyTrimmedString } from '@woorcs/utils/formik'

import { FormField, SubmitButton } from '../Formik'

const CustomerForm = t.type({
  name: NonEmptyTrimmedString
})

export type CustomerForm = t.TypeOf<typeof CustomerForm>

const validateForm = getFormikValidator(CustomerForm)

const defaultInitialValues = {
  name: ''
}

interface CustomerFormModalProps {
  title: string
  visible?: boolean
  initialValues?: Record<keyof CustomerForm, unknown>
  children: ReactElement
  onVisiblityChange?(visible: boolean): void
  onSubmit(customer: CustomerForm, actions: FormikHelpers<CustomerForm>): void
}

export const CustomerFormModal = ({
  title,
  visible,
  onSubmit,
  children,
  onVisiblityChange,
  initialValues = defaultInitialValues
}: CustomerFormModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal open={visible} setOpen={onVisiblityChange}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog aria-label={title} role='dialog'>
        <ModalHeader>
          <ModalTitle mb={6}>{title}</ModalTitle>
        </ModalHeader>
        <Formik
          validate={validateForm}
          initialValues={initialValues as any}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <ModalBody>
                <FormField name='name' label={t('common:name')} required>
                  <Input />
                </FormField>
              </ModalBody>

              <ModalFooter>
                <ModalDismiss render={<SecondaryButton mr={2} />}>
                  {t('common:cancel')}
                </ModalDismiss>
                <SubmitButton label={t('common:save')} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
