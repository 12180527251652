import { forwardRef } from 'react'

import { system, PropsOf } from '../../../system'
import { IconButton } from '../../buttons'
import { InfoIcon } from '../../icons'
import { Box, Flex } from '../../layout'
import { Tooltip } from '../../overlays'
import { Text } from '../../Text'

import { useFormFieldLabel } from './useFormField'

const Section = system('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
  // borderBottomColor: 'grey.50',
  // borderBottomWidth: '1px',
  // borderBottomStyle: 'solid'
})

const Label = system('label')({
  display: 'block',
  fontSize: 'small',
  fontWeight: 'bold',
  lineHeight: 1.25,
  color: 'grey.900',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent'
})

const RequiredIndicator = system('span')({
  color: 'danger',
  fontWeight: 'bold'
})

interface FormFieldLabelProps extends PropsOf<typeof system.label> {
  required?: boolean
  informativeText?: string | null
  description?: string | null
}

export const FormFieldLabel = forwardRef<
  typeof system.label,
  FormFieldLabelProps
>(
  (
    { children, informativeText, description, ...other }: FormFieldLabelProps,
    ref
  ) => {
    const { required, ...props } = useFormFieldLabel(other)

    return (
      <Box mb={2}>
        <Section flex={1}>
          <Flex alignItems='center'>
            <Label ref={ref} {...props}>
              {children}

              {required && (
                <RequiredIndicator role='presentation' aria-hidden>
                  *
                </RequiredIndicator>
              )}
            </Label>
          </Flex>
          {informativeText && (
            <Tooltip title={informativeText} aria-label='Field info'>
              <IconButton size='small' as='span' mb={1}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Section>
        {description && <Text>{description}</Text>}
      </Box>
    )
  }
)
