import { ReactElement } from 'react'
import { DialogDismiss, useDialogContext } from '@ariakit/react'

import { Button, SecondaryButton } from '../../buttons'
import { Body } from '../../typography'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogDisclosure,
  AlertDialogTitle
} from '../AlertDialog'

interface ConfirmButtonProps {
  destructive?: boolean
  label: string
  onConfirm(e: React.MouseEvent<HTMLButtonElement>): void
}

const ConfirmButton = ({
  destructive,
  label,
  onConfirm
}: ConfirmButtonProps) => {
  const context = useDialogContext()

  return (
    <Button
      colorVariant={destructive ? 'danger' : 'primary'}
      onClick={(e) => {
        onConfirm(e)
        context?.hide()
      }}
    >
      {label}
    </Button>
  )
}

type ConfirmAlertDialogProps = {
  title: string
  body: string
  confirmLabel?: string
  cancelLabel?: string
  children: ReactElement
  destructive?: boolean
  onConfirm(): void
}

export const ConfirmAlertDialog = ({
  title,
  body,
  confirmLabel = 'Ok',
  cancelLabel = 'Cancel',
  children,
  destructive = false,
  onConfirm
}: ConfirmAlertDialogProps) => {
  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onConfirm()
  }

  return (
    <AlertDialog>
      <AlertDialogDisclosure>{children}</AlertDialogDisclosure>
      <AlertDialogContent aria-label={title}>
        <AlertDialogHeader mb={6}>
          <AlertDialogTitle>{title}</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>{body}</Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <DialogDismiss
            render={<SecondaryButton mr={2}>{cancelLabel}</SecondaryButton>}
          />

          <ConfirmButton
            destructive={destructive}
            label={confirmLabel}
            onConfirm={handleConfirm}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
