import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  BriefcaseIcon,
  Flex,
  Pagination,
  Spinner,
  UserIcon
} from '@woorcs/design-system'
import * as Lens from 'monocle-ts/Lens'
import { pipe } from 'fp-ts/function'
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray'
import * as O from 'fp-ts/Option'
import * as DE from '@nll/datum/DatumEither'
import { useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'
import { PageInfo } from '@woorcs/graphql/schema'
import { useDebouncedCallback } from 'use-debounce'

import { ErrorView } from '../ErrorView'
import { EmptyState } from '../EmptyState'

import { List } from './List'
import { useCustomerListFiltersContext } from './CustomerFilters/filters'
import {
  CustomerListQuery,
  CustomerListQueryDocument
} from './__generated__/CustomerList'

type CustomerListProps = {
  onRemoveCustomer(customerId: string): void
}

export const customerListQueryResponseLens = pipe(
  Lens.id<CustomerListQuery>(),
  Lens.prop('customers')
)

export const customerListQueryResponseOptional = Lens.asOptional(
  customerListQueryResponseLens
)

interface ListPaginationProps {
  onOffsetChange(offset: number): void
  pageInfo: PageInfo
}

const ListPagination = ({ pageInfo, onOffsetChange }: ListPaginationProps) => {
  const handlePageChange = useCallback(
    (page: number) => {
      const offset = pageInfo.limit * (page - 1)

      onOffsetChange(offset)
    },
    [onOffsetChange, pageInfo.limit]
  )

  return (
    <Pagination
      currentPage={pageInfo.offset / pageInfo.limit + 1}
      totalPages={Math.max(Math.ceil(pageInfo.totalCount / pageInfo.limit), 1)}
      onChange={handlePageChange}
    />
  )
}

export const CustomerList = ({ onRemoveCustomer }: CustomerListProps) => {
  const { filters, limit, offset, setOffset } = useCustomerListFiltersContext()
  const [debounced, setDebounced] = useState(O.toNullable(filters.query))
  const debounce = useDebouncedCallback((query: string | null) => {
    setDebounced(query)
  }, 200)

  useEffect(() => {
    debounce.callback(O.toNullable(filters.query))
  }, [debounce, filters.query])

  const variables = useMemo(
    () => ({
      query: debounced,
      offset,
      limit
    }),
    [limit, offset, debounced]
  )

  return pipe(
    useQuery({
      query: CustomerListQueryDocument,
      variables,
      requestPolicy: 'cache-and-network',
      context: useMemo(
        () => ({
          suspense: false
        }),
        []
      )
    }),
    queryToDatumEither(customerListQueryResponseOptional),
    DE.squash(
      (loading) => {
        if (loading) {
          return (
            <Box
              display='flex'
              py={10}
              justifyContent='center'
              alignItems='center'
            >
              <Spinner />
            </Box>
          )
        }

        return (
          <EmptyState
            Icon={BriefcaseIcon}
            title={`No customers found`}
            description='No customers found for the current filters'
          />
        )
      },
      () => (
        <ErrorView body='Something went wrong while fetching the submissions' />
      ),
      ({ data, pageInfo }) =>
        pipe(
          RNEA.fromArray(data),
          O.fold(
            () => (
              <Flex flex={1} flexDirection='column' justifyContent='center'>
                <EmptyState
                  Icon={BriefcaseIcon}
                  title={`No customers found`}
                  description='No customers found for the current filters'
                />
              </Flex>
            ),
            () => (
              <Box>
                <List customers={data} onRowRemove={onRemoveCustomer} />

                <Box px={12} pb={8}>
                  <ListPagination
                    pageInfo={pageInfo}
                    onOffsetChange={setOffset}
                  />
                </Box>
              </Box>
            )
          )
        )
    )
  )
}
