import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type EditFormFormFragment = { __typename: 'Form', id: string, title: string, latestRevision: { __typename: 'FormRevision', id: string, definition: any, publishedAt: any | null, createdAt: any }, revisions: Array<{ __typename: 'FormRevision', id: string, definition: any, version: number, publishedAt: any | null, updatedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }> };

export type EditFormFormRevisionFragment = { __typename: 'FormRevision', id: string, definition: any, version: number, publishedAt: any | null, updatedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus };

export type UpdateFormRevisionMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateFormInput;
}>;


export type UpdateFormRevisionMutation = { __typename: 'Mutation', updateForm: { __typename: 'FormNotFoundError' } | { __typename: 'UpdateFormSuccess', form: { __typename: 'Form', id: string, title: string, latestRevision: { __typename: 'FormRevision', id: string, definition: any, publishedAt: any | null, createdAt: any }, revisions: Array<{ __typename: 'FormRevision', id: string, definition: any, version: number, publishedAt: any | null, updatedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }> } } };

export type PublishFormRevisionMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.PublishFormRevisionInput;
}>;


export type PublishFormRevisionMutation = { __typename: 'Mutation', publishFormRevision: { __typename: 'PublishFormRevisionSuccess', form: { __typename: 'Form', id: string, title: string, latestRevision: { __typename: 'FormRevision', id: string, definition: any, publishedAt: any | null, createdAt: any }, revisions: Array<{ __typename: 'FormRevision', id: string, definition: any, version: number, publishedAt: any | null, updatedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }> } } };

export const EditFormFormRevisionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EditFormFormRevisionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"definition"}},{"kind":"Field","name":{"kind":"Name","value":"version"}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]} as unknown as DocumentNode<EditFormFormRevisionFragment, unknown>;
export const EditFormFormFragmentDoc = {"kind":"Document", "definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EditFormFormFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Form"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"latestRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"definition"}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"revisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EditFormFormRevisionFragment"}}]}}]}},...EditFormFormRevisionFragmentDoc.definitions]} as unknown as DocumentNode<EditFormFormFragment, unknown>;
export const UpdateFormRevisionDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFormRevision"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateForm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EditFormFormFragment"}}]}}]}}]}}]}},...EditFormFormFragmentDoc.definitions]} as unknown as DocumentNode<UpdateFormRevisionMutation, UpdateFormRevisionMutationVariables>;
export const PublishFormRevisionDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PublishFormRevision"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PublishFormRevisionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"publishFormRevision"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PublishFormRevisionSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EditFormFormFragment"}}]}}]}}]}}]}},...EditFormFormFragmentDoc.definitions]} as unknown as DocumentNode<PublishFormRevisionMutation, PublishFormRevisionMutationVariables>;