import { translations } from '@woorcs/inspection-form-editor'

import common from './common.json'
import app from './app.json'
import onboarding from './onboarding.json'
import organizations from './organizations.json'
import submissions from './submissions.json'
import users from './users.json'
import viewer from './viewer.json'
import tags from './tags.json'
import forms from './forms.json'
import roles from './roles.json'
import dashboard from './dashboard.json'
import activityLogList from './activity-log-list.json'
import mySubmissions from './my-submissions.json'
import lobby from './lobby.json'
import customers from './customers.json'
import formDetails from './form-details.json'

export const sv = {
  app,
  common,
  onboarding,
  organizations,
  submissions,
  users,
  viewer,
  tags,
  forms,
  roles,
  dashboard,
  lobby,
  'activity-log-list': activityLogList,
  'my-submissions': mySubmissions,
  'inspection-form-editor': translations.sv,
  'form-details': formDetails,
  customers
} as const
