import { useParams } from 'react-router'
import { useQuery } from 'urql'
import { Suspense, useMemo } from 'react'
import { Box, Flex, Tab, TabList, Tabs } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'

import {
  FiltersProvider,
  ListPage,
  ListPageContent,
  ListPageHeader,
  PageTitle,
  SubmissionList,
  useSubmissionFilters,
  FullscreenLoader
} from '@app/components'

import { CustomerDetailsQueryDocument } from './__generated__/CustomerDetails'

interface CustomerDetailsHeaderProps {
  customerId: string
}

const CustomerDetailsHeader = ({ customerId }: CustomerDetailsHeaderProps) => {
  const { t } = useTranslation('users')
  const [query] = useQuery({
    query: CustomerDetailsQueryDocument,
    variables: { id: customerId as string },
    context: useMemo(
      () => ({
        suspense: true
      }),
      []
    )
  })

  const customer = query.data?.customer

  return (
    <ListPageHeader borderBottom='base' mb={0} pb={0}>
      <Box width='100%'>
        <Box mb={6}>
          <Flex flexDirection='row' alignItems='center' gridColumnGap={4}>
            <PageTitle mb={2}>{customer?.name}</PageTitle>
          </Flex>
        </Box>
        <TabList aria-label='Customer tabs' size='large' marginBottom='-1px'>
          <Tab>{t('details.tabs.submissions')}</Tab>
        </TabList>
      </Box>
    </ListPageHeader>
  )
}

interface CustomerSubmissionsListProps {
  customerId: string
}

const CustomerSubmissionsList = ({
  customerId
}: CustomerSubmissionsListProps) => {
  const filtersContext = useSubmissionFilters({
    customerId
  })

  return (
    <FiltersProvider value={filtersContext}>
      <SubmissionList />
    </FiltersProvider>
  )
}

export const CustomerDetailsPage = () => {
  const { customerId } = useParams()

  return (
    <Suspense fallback={<FullscreenLoader />}>
      <Tabs>
        <ListPage>
          <CustomerDetailsHeader customerId={customerId as string} />
          <ListPageContent px={0}>
            <Suspense fallback={<FullscreenLoader />}>
              <CustomerSubmissionsList customerId={customerId as string} />
            </Suspense>
          </ListPageContent>
        </ListPage>
      </Tabs>
    </Suspense>
  )
}
