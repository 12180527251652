
/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type Account = {
  __typename: 'Account';
  email: Scalars['String'];
  id: Scalars['ID'];
  superuser: Scalars['Boolean'];
  users: Array<User>;
};

export type ActivityLogEntry = {
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
};

export type ActivityLogInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  userId: InputMaybe<Scalars['ID']>;
};

/** queries */
export type ActivityLogListPage = {
  __typename: 'ActivityLogListPage';
  data: Array<ActivityLogEntry>;
  pageInfo: PageInfo;
};

export type AddUserToOrganizationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: InputMaybe<Role>;
  tags: Array<Scalars['ID']>;
};

export type AddUserToOrganizationPayload = AddUserToOrganizationSuccess | UserAlreadyAddedError;

export type AddUserToOrganizationSuccess = {
  __typename: 'AddUserToOrganizationSuccess';
  user: User;
};

export type BaseActivityLogEntry = {
  __typename: 'BaseActivityLogEntry';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
};

export type CreateCustomerInput = {
  name: Scalars['String'];
};

export type CreateCustomerPayload = CreateCustomerSuccess;

export type CreateCustomerSuccess = {
  __typename: 'CreateCustomerSuccess';
  customer: Customer;
};

export type CreateFormInput = {
  definition: InputMaybe<Scalars['JSON']>;
  editable: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type CreateFormPayload = CreateFormSuccess;

export type CreateFormSuccess = {
  __typename: 'CreateFormSuccess';
  form: Form;
};

export type CreateSubmissionInput = {
  formId: Scalars['ID'];
  language: InputMaybe<Scalars['String']>;
  location: InputMaybe<PointInput>;
  submissionData: Scalars['JSON'];
};

export type CreateSubmissionPayload = CreateSubmissionSuccess | SubmissionNotFoundError;

export type CreateSubmissionRevisionInput = {
  submissionId: Scalars['ID'];
};

export type CreateSubmissionRevisionPayload = CreateSubmissionRevisionSuccess | SubmissionNotFoundError;

export type CreateSubmissionRevisionSuccess = {
  __typename: 'CreateSubmissionRevisionSuccess';
  submission: Submission;
};

export type CreateSubmissionSuccess = {
  __typename: 'CreateSubmissionSuccess';
  submission: Submission;
};

export type CreateTagInput = {
  color: InputMaybe<Scalars['String']>;
  description: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTagPayload = CreateTagSuccess | TagExistsError;

export type CreateTagSuccess = {
  __typename: 'CreateTagSuccess';
  tag: Tag;
};

export type Customer = {
  __typename: 'Customer';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CustomerListPage = {
  __typename: 'CustomerListPage';
  data: Array<Customer>;
  pageInfo: PageInfo;
};

export type CustomerNotFoundError = Error & {
  __typename: 'CustomerNotFoundError';
  message: Scalars['String'];
};

export type DeleteCustomerInput = {
  id: Scalars['ID'];
};

export type DeleteCustomerPayload = DeleteCustomerSuccess;

export type DeleteCustomerSuccess = {
  __typename: 'DeleteCustomerSuccess';
  success: Scalars['Boolean'];
};

export type DeleteFormError = Error & {
  __typename: 'DeleteFormError';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteFormInput = {
  formId: Scalars['ID'];
};

export type DeleteFormPayload = DeleteFormError | DeleteFormSuccess;

export type DeleteFormRevisionError = Error & {
  __typename: 'DeleteFormRevisionError';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteFormRevisionInput = {
  formRevisionId: Scalars['ID'];
};

export type DeleteFormRevisionPayload = DeleteFormRevisionError | DeleteFormRevisionSuccess;

export type DeleteFormRevisionSuccess = {
  __typename: 'DeleteFormRevisionSuccess';
  success: Scalars['Boolean'];
};

export type DeleteFormSuccess = {
  __typename: 'DeleteFormSuccess';
  success: Scalars['Boolean'];
};

export type DeleteSubmissionError = Error & {
  __typename: 'DeleteSubmissionError';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteSubmissionInput = {
  submissionId: Scalars['ID'];
};

export type DeleteSubmissionPayload = DeleteSubmissionError | DeleteSubmissionSuccess;

export type DeleteSubmissionRevisionError = Error & {
  __typename: 'DeleteSubmissionRevisionError';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteSubmissionRevisionInput = {
  submissionRevisionId: Scalars['ID'];
};

export type DeleteSubmissionRevisionPayload = DeleteSubmissionRevisionError | DeleteSubmissionRevisionSuccess;

export type DeleteSubmissionRevisionSuccess = {
  __typename: 'DeleteSubmissionRevisionSuccess';
  success: Scalars['Boolean'];
};

export type DeleteSubmissionSuccess = {
  __typename: 'DeleteSubmissionSuccess';
  success: Scalars['Boolean'];
};

export type DeleteTagInput = {
  id: Scalars['ID'];
};

export type DeleteTagPayload = DeleteTagSuccess;

export type DeleteTagSuccess = {
  __typename: 'DeleteTagSuccess';
  success: Scalars['Boolean'];
};

export type DisableUserInput = {
  userId: Scalars['ID'];
};

export type DisableUserPayload = DisableUserSuccess;

export type DisableUserSuccess = {
  __typename: 'DisableUserSuccess';
  user: User;
};

export type EnableUserPayload = EnableUserSuccess;

export type EnableUserSuccess = {
  __typename: 'EnableUserSuccess';
  user: User;
};

/** mutations */
export type Error = {
  message: Scalars['String'];
};

export type Form = {
  __typename: 'Form';
  active: Maybe<FormRevision>;
  createdAt: Scalars['Date'];
  description: Maybe<Scalars['String']>;
  editable: Scalars['Boolean'];
  filenameFormat: Array<SubmissionFilenameComponent>;
  id: Scalars['ID'];
  latestRevision: FormRevision;
  requireCustomer: Scalars['Boolean'];
  revisions: Array<FormRevision>;
  title: Scalars['String'];
};

export type FormCreatedActivity = ActivityLogEntry & {
  __typename: 'FormCreatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type FormDeletedActivity = ActivityLogEntry & {
  __typename: 'FormDeletedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type FormNotFoundError = Error & {
  __typename: 'FormNotFoundError';
  message: Scalars['String'];
};

export type FormPublishedActivity = ActivityLogEntry & {
  __typename: 'FormPublishedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  revisionId: Scalars['ID'];
  title: Scalars['String'];
};

export type FormRevision = {
  __typename: 'FormRevision';
  createdAt: Scalars['Date'];
  definition: Scalars['JSON'];
  id: Scalars['ID'];
  parent: Form;
  publishedAt: Maybe<Scalars['Date']>;
  status: FormRevisionStatus;
  submissions: Array<Submission>;
  updatedAt: Maybe<Scalars['Date']>;
  version: Scalars['Int'];
};

export type FormRevisionDeletedActivity = ActivityLogEntry & {
  __typename: 'FormRevisionDeletedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export enum FormRevisionStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type FormTypeSubmissionCount = {
  __typename: 'FormTypeSubmissionCount';
  completedSubmissionCount: Scalars['Int'];
  createdSubmissionCount: Scalars['Int'];
  formName: Scalars['String'];
  id: Scalars['ID'];
};

export type FormUpdatedActivity = ActivityLogEntry & {
  __typename: 'FormUpdatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  addUserToOrganization: AddUserToOrganizationPayload;
  createCustomer: CreateCustomerPayload;
  createForm: CreateFormPayload;
  createSubmission: CreateSubmissionPayload;
  createSubmissionRevision: CreateSubmissionRevisionPayload;
  createTag: CreateTagPayload;
  deleteCustomer: DeleteCustomerPayload;
  deleteForm: DeleteFormPayload;
  deleteFormRevision: DeleteFormRevisionPayload;
  deleteSubmission: DeleteSubmissionPayload;
  deleteSubmissionRevision: DeleteSubmissionRevisionPayload;
  deleteTag: DeleteTagPayload;
  disableUser: DisableUserPayload;
  enableUser: EnableUserPayload;
  publishFormRevision: PublishFormRevisionPayload;
  publishSubmissionRevision: PublishSubmissionRevisionPayload;
  removeUsersFromOrganization: RemoveUsersPayload;
  requestCsvExport: Maybe<Scalars['Boolean']>;
  requestLogoUploadUrl: RequestUploadUrlPayload;
  requestSubmissionUploadUrl: RequestUploadUrlPayload;
  shareSubmissionPdf: ShareSubmissionPdfPayload;
  storeSubmissionPdf: StoreSubmissionPdfPayload;
  updateCustomer: UpdateCustomerPayload;
  updateForm: UpdateFormPayload;
  updateFormDescription: UpdateFormDescriptionPayload;
  updateFormRequireCustomer: UpdateFormRequireCustomerPayload;
  updateFormSubmissionFilenameFormat: UpdateFormSubmissionFilenameFormatPayload;
  updateFormTitle: UpdateFormTitlePayload;
  updateLogo: UpdateLogoPayload;
  updateSubmissionCustomer: UpdateSubmissionCustomerPayload;
  updateSubmissionLocation: UpdateSubmissionLocationPayload;
  updateSubmissionRevision: UpdateSubmissionRevisionPayload;
  updateTag: UpdateTagPayload;
  updateUser: UpdateUserPayload;
  updateViewer: UpdateViewerPayload;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateFormArgs = {
  input: CreateFormInput;
};


export type MutationCreateSubmissionArgs = {
  input: CreateSubmissionInput;
};


export type MutationCreateSubmissionRevisionArgs = {
  input: CreateSubmissionRevisionInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};


export type MutationDeleteFormArgs = {
  input: DeleteFormInput;
};


export type MutationDeleteFormRevisionArgs = {
  input: DeleteFormRevisionInput;
};


export type MutationDeleteSubmissionArgs = {
  input: DeleteSubmissionInput;
};


export type MutationDeleteSubmissionRevisionArgs = {
  input: DeleteSubmissionRevisionInput;
};


export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};


export type MutationDisableUserArgs = {
  input: DisableUserInput;
};


export type MutationEnableUserArgs = {
  input: DisableUserInput;
};


export type MutationPublishFormRevisionArgs = {
  input: PublishFormRevisionInput;
};


export type MutationPublishSubmissionRevisionArgs = {
  input: PublishSubmissionRevisionInput;
};


export type MutationRemoveUsersFromOrganizationArgs = {
  input: RemoveUsersFromOrganizationInput;
};


export type MutationRequestCsvExportArgs = {
  input: SubmissionFilter;
};


export type MutationRequestLogoUploadUrlArgs = {
  input: RequestUploadUrlInput;
};


export type MutationRequestSubmissionUploadUrlArgs = {
  input: RequestUploadUrlInput;
};


export type MutationShareSubmissionPdfArgs = {
  input: ShareSubmissionPdfInput;
};


export type MutationStoreSubmissionPdfArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateFormArgs = {
  input: UpdateFormInput;
};


export type MutationUpdateFormDescriptionArgs = {
  input: UpdateFormDescriptionInput;
};


export type MutationUpdateFormRequireCustomerArgs = {
  input: UpdateFormRequireCustomerInput;
};


export type MutationUpdateFormSubmissionFilenameFormatArgs = {
  input: UpdateFormSubmissionFilenameFormatInput;
};


export type MutationUpdateFormTitleArgs = {
  input: UpdateFormTitleInput;
};


export type MutationUpdateLogoArgs = {
  input: UpdateLogoInput;
};


export type MutationUpdateSubmissionCustomerArgs = {
  input: UpdateSubmissionCustomerInput;
};


export type MutationUpdateSubmissionLocationArgs = {
  input: UpdateSubmissionLocationInput;
};


export type MutationUpdateSubmissionRevisionArgs = {
  input: UpdateSubmissionRevisionInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateViewerArgs = {
  input: UpdateViewerInput;
};

export type Organization = {
  __typename: 'Organization';
  createdAt: Scalars['Date'];
  forms: Array<Form>;
  id: Scalars['ID'];
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tags: Array<Tag>;
};

export type OrganizationCreatedActivity = ActivityLogEntry & {
  __typename: 'OrganizationCreatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PageInfo = {
  __typename: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum PeriodInterval {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type Point = {
  __typename: 'Point';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ProfileCreatedActivity = ActivityLogEntry & {
  __typename: 'ProfileCreatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export type ProfileDisabledActivity = ActivityLogEntry & {
  __typename: 'ProfileDisabledActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProfileEnabledActivity = ActivityLogEntry & {
  __typename: 'ProfileEnabledActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProfileUpdatedActivity = ActivityLogEntry & {
  __typename: 'ProfileUpdatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProfilesDeletedActivity = ActivityLogEntry & {
  __typename: 'ProfilesDeletedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  names: Array<Scalars['String']>;
  profileIds: Array<Scalars['Int']>;
};

export type PublishFormRevisionInput = {
  formRevisionId: Scalars['ID'];
};

export type PublishFormRevisionPayload = PublishFormRevisionSuccess;

export type PublishFormRevisionSuccess = {
  __typename: 'PublishFormRevisionSuccess';
  form: Form;
};

export type PublishSubmissionRevisionInput = {
  submissionRevisionId: Scalars['ID'];
};

export type PublishSubmissionRevisionPayload = PublishSubmissionRevisionSuccess | SubmissionRevisionAlreadyPublished | SubmissionRevisionInvalid | SubmissionRevisionNotFoundError;

export type PublishSubmissionRevisionSuccess = {
  __typename: 'PublishSubmissionRevisionSuccess';
  submission: Submission;
};

export type Query = {
  __typename: 'Query';
  activityLog: ActivityLogListPage;
  customer: Maybe<Customer>;
  customers: CustomerListPage;
  form: Form;
  formRevision: FormRevision;
  forms: Array<Form>;
  organization: Organization;
  searchUsers: Array<User>;
  submission: Submission;
  submissionReport: Array<SubmissionReportData>;
  submissions: SubmissionListPage;
  tag: Maybe<Tag>;
  user: Maybe<User>;
  users: UserListPage;
  viewer: User;
};


export type QueryActivityLogArgs = {
  input: ActivityLogInput;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFormArgs = {
  id: Scalars['ID'];
};


export type QueryFormRevisionArgs = {
  id: Scalars['ID'];
};


export type QuerySearchUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
};


export type QuerySubmissionArgs = {
  id: Scalars['ID'];
};


export type QuerySubmissionReportArgs = {
  input: SubmissionReportInput;
};


export type QuerySubmissionsArgs = {
  creatorId: InputMaybe<Scalars['ID']>;
  customerId: InputMaybe<Scalars['ID']>;
  formId: InputMaybe<Scalars['ID']>;
  from: InputMaybe<Scalars['Date']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  to: InputMaybe<Scalars['Date']>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type RemoveSelfError = Error & {
  __typename: 'RemoveSelfError';
  message: Scalars['String'];
};

export type RemoveUsersFromOrganizationInput = {
  userIds: Array<Scalars['ID']>;
};

export type RemoveUsersPayload = RemoveSelfError | RemoveUsersSuccess;

export type RemoveUsersSuccess = {
  __typename: 'RemoveUsersSuccess';
  success: Scalars['Boolean'];
};

export type RequestUploadUrlInput = {
  extension: Scalars['String'];
};

export type RequestUploadUrlPayload = RequestUploadUrlSuccess | StorageNotDefined;

export type RequestUploadUrlSuccess = {
  __typename: 'RequestUploadUrlSuccess';
  filename: Scalars['String'];
  publicUrl: Scalars['String'];
  url: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN'
}

export type ShareSubmissionPdfInput = {
  email: Scalars['String'];
  language: InputMaybe<Scalars['String']>;
  submissionRevisionId: Scalars['ID'];
};

export type ShareSubmissionPdfPayload = ShareSubmissionPdfSuccess;

export type ShareSubmissionPdfSuccess = {
  __typename: 'ShareSubmissionPdfSuccess';
  success: Scalars['Boolean'];
};

export type StorageNotDefined = {
  __typename: 'StorageNotDefined';
  message: Scalars['String'];
};

export type StoreSubmissionPdfPayload = StoreSubmissionPdfSuccess;

export type StoreSubmissionPdfSuccess = {
  __typename: 'StoreSubmissionPdfSuccess';
  pdfPath: Scalars['String'];
};

export type Submission = {
  __typename: 'Submission';
  current: SubmissionRevision;
  customer: Maybe<Customer>;
  formRevision: FormRevision;
  id: Scalars['ID'];
  language: Maybe<Scalars['String']>;
  location: Maybe<Point>;
  revisions: Array<SubmissionRevision>;
  user: User;
};

export type SubmissionCreatedActivity = ActivityLogEntry & {
  __typename: 'SubmissionCreatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  creator: Scalars['String'];
  form: Scalars['String'];
  id: Scalars['ID'];
};

export type SubmissionDeletedActivity = ActivityLogEntry & {
  __typename: 'SubmissionDeletedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
};

export type SubmissionFilenameComponent = SubmissionFilenameCustomerComponent | SubmissionFilenameDateComponent | SubmissionFilenameFormFieldComponent | SubmissionFilenameSubmitterComponent;

export enum SubmissionFilenameComponentType {
  Customer = 'customer',
  Date = 'date',
  FormField = 'formField',
  Submitter = 'submitter'
}

export type SubmissionFilenameCustomerComponent = {
  __typename: 'SubmissionFilenameCustomerComponent';
  customerProperty: SubmissionFilenameCustomerProperty;
};

export type SubmissionFilenameCustomerInput = {
  customerProperty: SubmissionFilenameCustomerProperty;
};

export enum SubmissionFilenameCustomerProperty {
  Name = 'name'
}

export type SubmissionFilenameDateComponent = {
  __typename: 'SubmissionFilenameDateComponent';
  dateFormat: SubmissionFilenameDateFormat;
};

export enum SubmissionFilenameDateFormat {
  Yyyymmdd = 'YYYYMMDD'
}

export type SubmissionFilenameDateInput = {
  dateFormat: SubmissionFilenameDateFormat;
};

export type SubmissionFilenameFormFieldComponent = {
  __typename: 'SubmissionFilenameFormFieldComponent';
  fieldKey: Scalars['String'];
};

export type SubmissionFilenameFormFieldInput = {
  fieldKey: Scalars['String'];
};

export type SubmissionFilenameFormatInput = {
  customer: InputMaybe<SubmissionFilenameCustomerInput>;
  date: InputMaybe<SubmissionFilenameDateInput>;
  formField: InputMaybe<SubmissionFilenameFormFieldInput>;
  submitter: InputMaybe<SubmissionFilenameSubmitterInput>;
  type: SubmissionFilenameComponentType;
};

export type SubmissionFilenameSubmitterComponent = {
  __typename: 'SubmissionFilenameSubmitterComponent';
  submitterProperty: SubmissionFilenameSubmitterProperty;
};

export type SubmissionFilenameSubmitterInput = {
  submitterProperty: SubmissionFilenameSubmitterProperty;
};

export enum SubmissionFilenameSubmitterProperty {
  Name = 'name'
}

export type SubmissionFilter = {
  creatorId: InputMaybe<Scalars['ID']>;
  formId: Scalars['ID'];
  from: InputMaybe<Scalars['Date']>;
  tags: InputMaybe<Array<Scalars['ID']>>;
  to: InputMaybe<Scalars['Date']>;
};

export type SubmissionListPage = {
  __typename: 'SubmissionListPage';
  data: Array<Submission>;
  pageInfo: PageInfo;
};

export type SubmissionLocationUpdatedActivity = ActivityLogEntry & {
  __typename: 'SubmissionLocationUpdatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  form: Scalars['String'];
  id: Scalars['ID'];
  location: Maybe<Point>;
  revisionId: Scalars['ID'];
  updater: Scalars['String'];
};

export type SubmissionNotFoundError = Error & {
  __typename: 'SubmissionNotFoundError';
  message: Scalars['String'];
};

export type SubmissionPublishedActivity = ActivityLogEntry & {
  __typename: 'SubmissionPublishedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  form: Scalars['String'];
  id: Scalars['ID'];
  publisher: Scalars['String'];
  revisionId: Scalars['ID'];
};

export type SubmissionReportData = {
  __typename: 'SubmissionReportData';
  completedSubmissionCount: Scalars['Int'];
  createdSubmissionCount: Scalars['Int'];
  id: Scalars['String'];
  interval: PeriodInterval;
  periodStart: Scalars['Date'];
  submissionCountByFormType: Array<FormTypeSubmissionCount>;
  uniqueUserCount: Scalars['Int'];
};

export type SubmissionReportInput = {
  endDate: Scalars['Date'];
  interval: PeriodInterval;
  startDate: Scalars['Date'];
};

export type SubmissionRevision = {
  __typename: 'SubmissionRevision';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  parent: Submission;
  publishedAt: Maybe<Scalars['Date']>;
  status: SubmissionRevisionStatus;
  submissionData: Scalars['JSON'];
  version: Scalars['Int'];
};

export type SubmissionRevisionAlreadyPublished = Error & {
  __typename: 'SubmissionRevisionAlreadyPublished';
  message: Scalars['String'];
};

export type SubmissionRevisionCreatedActivity = ActivityLogEntry & {
  __typename: 'SubmissionRevisionCreatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  form: Scalars['String'];
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export type SubmissionRevisionDeletedActivity = ActivityLogEntry & {
  __typename: 'SubmissionRevisionDeletedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export type SubmissionRevisionInvalid = Error & {
  __typename: 'SubmissionRevisionInvalid';
  message: Scalars['String'];
};

export type SubmissionRevisionNotFoundError = Error & {
  __typename: 'SubmissionRevisionNotFoundError';
  message: Scalars['String'];
};

export enum SubmissionRevisionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type SubmissionUnpublishedActivity = ActivityLogEntry & {
  __typename: 'SubmissionUnpublishedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  form: Scalars['String'];
  id: Scalars['ID'];
  revisionId: Scalars['ID'];
  unpublisher: Scalars['String'];
};

export type SubmissionUpdatedActivity = ActivityLogEntry & {
  __typename: 'SubmissionUpdatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  form: Scalars['String'];
  id: Scalars['ID'];
  revisionId: Scalars['ID'];
  updater: Scalars['String'];
};

export type SubmissionsCsv = {
  __typename: 'SubmissionsCsv';
  csvPath: Scalars['String'];
};

export type SuperuserAddedActivity = ActivityLogEntry & {
  __typename: 'SuperuserAddedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type SuperuserRemovedActivity = ActivityLogEntry & {
  __typename: 'SuperuserRemovedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type Tag = {
  __typename: 'Tag';
  color: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  userCount: Scalars['Int'];
};

export type TagCreatedActivity = ActivityLogEntry & {
  __typename: 'TagCreatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  color: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TagDeletedActivity = ActivityLogEntry & {
  __typename: 'TagDeletedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TagExistsError = Error & {
  __typename: 'TagExistsError';
  message: Scalars['String'];
};

export type TagUpdatedActivity = ActivityLogEntry & {
  __typename: 'TagUpdatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  color: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCustomerInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCustomerPayload = UpdateCustomerSuccess;

export type UpdateCustomerSuccess = {
  __typename: 'UpdateCustomerSuccess';
  customer: Customer;
};

export type UpdateFormDescriptionInput = {
  description: Scalars['String'];
  formId: Scalars['ID'];
};

export type UpdateFormDescriptionPayload = FormNotFoundError | UpdateFormDescriptionSuccess;

export type UpdateFormDescriptionSuccess = {
  __typename: 'UpdateFormDescriptionSuccess';
  form: Form;
};

export type UpdateFormInput = {
  definition: Scalars['JSON'];
  editable: Scalars['Boolean'];
  formId: Scalars['ID'];
  title: Scalars['String'];
};

export type UpdateFormPayload = FormNotFoundError | UpdateFormSuccess;

export type UpdateFormRequireCustomerInput = {
  formId: Scalars['ID'];
  requireCustomer: Scalars['Boolean'];
};

export type UpdateFormRequireCustomerPayload = FormNotFoundError | UpdateFormRequireCustomerSuccess;

export type UpdateFormRequireCustomerSuccess = {
  __typename: 'UpdateFormRequireCustomerSuccess';
  form: Form;
};

export type UpdateFormSubmissionFilenameFormatError = Error & {
  __typename: 'UpdateFormSubmissionFilenameFormatError';
  message: Scalars['String'];
};

export type UpdateFormSubmissionFilenameFormatInput = {
  formId: Scalars['ID'];
  format: Array<SubmissionFilenameFormatInput>;
};

export type UpdateFormSubmissionFilenameFormatPayload = FormNotFoundError | UpdateFormSubmissionFilenameFormatError | UpdateFormSubmissionFilenameFormatSuccess;

export type UpdateFormSubmissionFilenameFormatSuccess = {
  __typename: 'UpdateFormSubmissionFilenameFormatSuccess';
  form: Form;
};

export type UpdateFormSuccess = {
  __typename: 'UpdateFormSuccess';
  form: Form;
};

export type UpdateFormTitleInput = {
  formId: Scalars['ID'];
  title: Scalars['String'];
};

export type UpdateFormTitlePayload = FormNotFoundError | UpdateFormTitleSuccess;

export type UpdateFormTitleSuccess = {
  __typename: 'UpdateFormTitleSuccess';
  form: Form;
};

export type UpdateLogoInput = {
  filename: Scalars['String'];
};

export type UpdateLogoPayload = UpdateLogoSuccess;

export type UpdateLogoSuccess = {
  __typename: 'UpdateLogoSuccess';
  organization: Organization;
};

export type UpdatePublishedSubmissionRevisionError = Error & {
  __typename: 'UpdatePublishedSubmissionRevisionError';
  message: Scalars['String'];
};

export type UpdateSubmissionCustomerInput = {
  /**
   * The ID of the customer to associate with the submission.
   * If not provided and a customer exists, the customer will be removed from the submission.
   */
  customerId: InputMaybe<Scalars['ID']>;
  submissionId: Scalars['ID'];
};

export type UpdateSubmissionCustomerPayload = CustomerNotFoundError | SubmissionNotFoundError | UpdateSubmissionCustomerSuccess;

export type UpdateSubmissionCustomerSuccess = {
  __typename: 'UpdateSubmissionCustomerSuccess';
  submission: Submission;
};

export type UpdateSubmissionLocationInput = {
  location: InputMaybe<PointInput>;
  submissionId: Scalars['ID'];
};

export type UpdateSubmissionLocationPayload = SubmissionNotFoundError | UpdateSubmissionLocationSuccess;

export type UpdateSubmissionLocationSuccess = {
  __typename: 'UpdateSubmissionLocationSuccess';
  submission: Submission;
};

export type UpdateSubmissionRevisionInput = {
  submissionData: Scalars['JSON'];
  submissionRevisionId: Scalars['ID'];
};

export type UpdateSubmissionRevisionPayload = SubmissionRevisionNotFoundError | UpdatePublishedSubmissionRevisionError | UpdateSubmissionRevisionSuccess;

export type UpdateSubmissionRevisionSuccess = {
  __typename: 'UpdateSubmissionRevisionSuccess';
  submission: Submission;
};

export type UpdateTagInput = {
  color: InputMaybe<Scalars['String']>;
  description: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateTagPayload = UpdateTagSuccess;

export type UpdateTagSuccess = {
  __typename: 'UpdateTagSuccess';
  tag: Tag;
};

export type UpdateUserInput = {
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  role: InputMaybe<Role>;
  tags: Array<Scalars['ID']>;
};

export type UpdateUserPayload = UpdateUserSuccess;

export type UpdateUserSuccess = {
  __typename: 'UpdateUserSuccess';
  user: User;
};

export type UpdateViewerEmailFailedError = Error & {
  __typename: 'UpdateViewerEmailFailedError';
  message: Scalars['String'];
};

export type UpdateViewerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateViewerPayload = UpdateViewerSuccess;

export type UpdateViewerSuccess = {
  __typename: 'UpdateViewerSuccess';
  viewer: User;
};

export type User = {
  __typename: 'User';
  account: Account;
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization: Organization;
  role: Maybe<Role>;
  status: UserStatus;
  submissionDrafts: Array<Submission>;
  submissions: Array<Submission>;
  tags: Array<Tag>;
};

export type UserAlreadyAddedError = Error & {
  __typename: 'UserAlreadyAddedError';
  message: Scalars['String'];
};

export type UserError = {
  message: Scalars['String'];
  path: Scalars['String'];
};

export type UserListPage = {
  __typename: 'UserListPage';
  data: Array<User>;
  pageInfo: PageInfo;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Pending = 'PENDING',
  Removed = 'REMOVED'
}

export type ViewerUpdatedActivity = ActivityLogEntry & {
  __typename: 'ViewerUpdatedActivity';
  action: Scalars['String'];
  affectedEntityId: Maybe<Scalars['ID']>;
  causedBy: Maybe<User>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
};


interface AnyType {
  __typename: string
}


    export const matchAddUserToOrganizationPayload =
      <A>(patterns: {
        [K in AddUserToOrganizationPayload['__typename']]: (t: Extract<AddUserToOrganizationPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<AddUserToOrganizationPayload, '__typename'> & Partial<Omit<AddUserToOrganizationPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchCreateCustomerPayload =
      <A>(patterns: {
        [K in CreateCustomerPayload['__typename']]: (t: Extract<CreateCustomerPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<CreateCustomerPayload, '__typename'> & Partial<Omit<CreateCustomerPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchCreateFormPayload =
      <A>(patterns: {
        [K in CreateFormPayload['__typename']]: (t: Extract<CreateFormPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<CreateFormPayload, '__typename'> & Partial<Omit<CreateFormPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchCreateSubmissionPayload =
      <A>(patterns: {
        [K in CreateSubmissionPayload['__typename']]: (t: Extract<CreateSubmissionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<CreateSubmissionPayload, '__typename'> & Partial<Omit<CreateSubmissionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchCreateSubmissionRevisionPayload =
      <A>(patterns: {
        [K in CreateSubmissionRevisionPayload['__typename']]: (t: Extract<CreateSubmissionRevisionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<CreateSubmissionRevisionPayload, '__typename'> & Partial<Omit<CreateSubmissionRevisionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchCreateTagPayload =
      <A>(patterns: {
        [K in CreateTagPayload['__typename']]: (t: Extract<CreateTagPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<CreateTagPayload, '__typename'> & Partial<Omit<CreateTagPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchDeleteCustomerPayload =
      <A>(patterns: {
        [K in DeleteCustomerPayload['__typename']]: (t: Extract<DeleteCustomerPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<DeleteCustomerPayload, '__typename'> & Partial<Omit<DeleteCustomerPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchDeleteFormPayload =
      <A>(patterns: {
        [K in DeleteFormPayload['__typename']]: (t: Extract<DeleteFormPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<DeleteFormPayload, '__typename'> & Partial<Omit<DeleteFormPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchDeleteFormRevisionPayload =
      <A>(patterns: {
        [K in DeleteFormRevisionPayload['__typename']]: (t: Extract<DeleteFormRevisionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<DeleteFormRevisionPayload, '__typename'> & Partial<Omit<DeleteFormRevisionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchDeleteSubmissionPayload =
      <A>(patterns: {
        [K in DeleteSubmissionPayload['__typename']]: (t: Extract<DeleteSubmissionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<DeleteSubmissionPayload, '__typename'> & Partial<Omit<DeleteSubmissionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchDeleteSubmissionRevisionPayload =
      <A>(patterns: {
        [K in DeleteSubmissionRevisionPayload['__typename']]: (t: Extract<DeleteSubmissionRevisionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<DeleteSubmissionRevisionPayload, '__typename'> & Partial<Omit<DeleteSubmissionRevisionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchDeleteTagPayload =
      <A>(patterns: {
        [K in DeleteTagPayload['__typename']]: (t: Extract<DeleteTagPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<DeleteTagPayload, '__typename'> & Partial<Omit<DeleteTagPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchDisableUserPayload =
      <A>(patterns: {
        [K in DisableUserPayload['__typename']]: (t: Extract<DisableUserPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<DisableUserPayload, '__typename'> & Partial<Omit<DisableUserPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchEnableUserPayload =
      <A>(patterns: {
        [K in EnableUserPayload['__typename']]: (t: Extract<EnableUserPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<EnableUserPayload, '__typename'> & Partial<Omit<EnableUserPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchPublishFormRevisionPayload =
      <A>(patterns: {
        [K in PublishFormRevisionPayload['__typename']]: (t: Extract<PublishFormRevisionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<PublishFormRevisionPayload, '__typename'> & Partial<Omit<PublishFormRevisionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchPublishSubmissionRevisionPayload =
      <A>(patterns: {
        [K in PublishSubmissionRevisionPayload['__typename']]: (t: Extract<PublishSubmissionRevisionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<PublishSubmissionRevisionPayload, '__typename'> & Partial<Omit<PublishSubmissionRevisionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchRemoveUsersPayload =
      <A>(patterns: {
        [K in RemoveUsersPayload['__typename']]: (t: Extract<RemoveUsersPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<RemoveUsersPayload, '__typename'> & Partial<Omit<RemoveUsersPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchRequestUploadUrlPayload =
      <A>(patterns: {
        [K in RequestUploadUrlPayload['__typename']]: (t: Extract<RequestUploadUrlPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<RequestUploadUrlPayload, '__typename'> & Partial<Omit<RequestUploadUrlPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchShareSubmissionPdfPayload =
      <A>(patterns: {
        [K in ShareSubmissionPdfPayload['__typename']]: (t: Extract<ShareSubmissionPdfPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<ShareSubmissionPdfPayload, '__typename'> & Partial<Omit<ShareSubmissionPdfPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchStoreSubmissionPdfPayload =
      <A>(patterns: {
        [K in StoreSubmissionPdfPayload['__typename']]: (t: Extract<StoreSubmissionPdfPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<StoreSubmissionPdfPayload, '__typename'> & Partial<Omit<StoreSubmissionPdfPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchSubmissionFilenameComponent =
      <A>(patterns: {
        [K in SubmissionFilenameComponent['__typename']]: (t: Extract<SubmissionFilenameComponent, { __typename: K }>) => A
      }) =>
      (f: Pick<SubmissionFilenameComponent, '__typename'> & Partial<Omit<SubmissionFilenameComponent, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateCustomerPayload =
      <A>(patterns: {
        [K in UpdateCustomerPayload['__typename']]: (t: Extract<UpdateCustomerPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateCustomerPayload, '__typename'> & Partial<Omit<UpdateCustomerPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateFormDescriptionPayload =
      <A>(patterns: {
        [K in UpdateFormDescriptionPayload['__typename']]: (t: Extract<UpdateFormDescriptionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateFormDescriptionPayload, '__typename'> & Partial<Omit<UpdateFormDescriptionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateFormPayload =
      <A>(patterns: {
        [K in UpdateFormPayload['__typename']]: (t: Extract<UpdateFormPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateFormPayload, '__typename'> & Partial<Omit<UpdateFormPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateFormRequireCustomerPayload =
      <A>(patterns: {
        [K in UpdateFormRequireCustomerPayload['__typename']]: (t: Extract<UpdateFormRequireCustomerPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateFormRequireCustomerPayload, '__typename'> & Partial<Omit<UpdateFormRequireCustomerPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateFormSubmissionFilenameFormatPayload =
      <A>(patterns: {
        [K in UpdateFormSubmissionFilenameFormatPayload['__typename']]: (t: Extract<UpdateFormSubmissionFilenameFormatPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateFormSubmissionFilenameFormatPayload, '__typename'> & Partial<Omit<UpdateFormSubmissionFilenameFormatPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateFormTitlePayload =
      <A>(patterns: {
        [K in UpdateFormTitlePayload['__typename']]: (t: Extract<UpdateFormTitlePayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateFormTitlePayload, '__typename'> & Partial<Omit<UpdateFormTitlePayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateLogoPayload =
      <A>(patterns: {
        [K in UpdateLogoPayload['__typename']]: (t: Extract<UpdateLogoPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateLogoPayload, '__typename'> & Partial<Omit<UpdateLogoPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateSubmissionCustomerPayload =
      <A>(patterns: {
        [K in UpdateSubmissionCustomerPayload['__typename']]: (t: Extract<UpdateSubmissionCustomerPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateSubmissionCustomerPayload, '__typename'> & Partial<Omit<UpdateSubmissionCustomerPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateSubmissionLocationPayload =
      <A>(patterns: {
        [K in UpdateSubmissionLocationPayload['__typename']]: (t: Extract<UpdateSubmissionLocationPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateSubmissionLocationPayload, '__typename'> & Partial<Omit<UpdateSubmissionLocationPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateSubmissionRevisionPayload =
      <A>(patterns: {
        [K in UpdateSubmissionRevisionPayload['__typename']]: (t: Extract<UpdateSubmissionRevisionPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateSubmissionRevisionPayload, '__typename'> & Partial<Omit<UpdateSubmissionRevisionPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateTagPayload =
      <A>(patterns: {
        [K in UpdateTagPayload['__typename']]: (t: Extract<UpdateTagPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateTagPayload, '__typename'> & Partial<Omit<UpdateTagPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateUserPayload =
      <A>(patterns: {
        [K in UpdateUserPayload['__typename']]: (t: Extract<UpdateUserPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateUserPayload, '__typename'> & Partial<Omit<UpdateUserPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const matchUpdateViewerPayload =
      <A>(patterns: {
        [K in UpdateViewerPayload['__typename']]: (t: Extract<UpdateViewerPayload, { __typename: K }>) => A
      }) =>
      (f: Pick<UpdateViewerPayload, '__typename'> & Partial<Omit<UpdateViewerPayload, '__typename'>>) =>
        patterns[f.__typename](f as any)
  

    export const addUserToOrganizationSuccess = (t: Omit<AddUserToOrganizationSuccess, '__typename'>): AddUserToOrganizationSuccess => ({
      __typename: 'AddUserToOrganizationSuccess',
      ...t
    })
  

    export const userAlreadyAddedError = (t: Omit<UserAlreadyAddedError, '__typename'>): UserAlreadyAddedError => ({
      __typename: 'UserAlreadyAddedError',
      ...t
    })
  

    export const createCustomerSuccess = (t: Omit<CreateCustomerSuccess, '__typename'>): CreateCustomerSuccess => ({
      __typename: 'CreateCustomerSuccess',
      ...t
    })
  

    export const createFormSuccess = (t: Omit<CreateFormSuccess, '__typename'>): CreateFormSuccess => ({
      __typename: 'CreateFormSuccess',
      ...t
    })
  

    export const createSubmissionSuccess = (t: Omit<CreateSubmissionSuccess, '__typename'>): CreateSubmissionSuccess => ({
      __typename: 'CreateSubmissionSuccess',
      ...t
    })
  

    export const submissionNotFoundError = (t: Omit<SubmissionNotFoundError, '__typename'>): SubmissionNotFoundError => ({
      __typename: 'SubmissionNotFoundError',
      ...t
    })
  

    export const createSubmissionRevisionSuccess = (t: Omit<CreateSubmissionRevisionSuccess, '__typename'>): CreateSubmissionRevisionSuccess => ({
      __typename: 'CreateSubmissionRevisionSuccess',
      ...t
    })
  

    export const createTagSuccess = (t: Omit<CreateTagSuccess, '__typename'>): CreateTagSuccess => ({
      __typename: 'CreateTagSuccess',
      ...t
    })
  

    export const tagExistsError = (t: Omit<TagExistsError, '__typename'>): TagExistsError => ({
      __typename: 'TagExistsError',
      ...t
    })
  

    export const deleteCustomerSuccess = (t: Omit<DeleteCustomerSuccess, '__typename'>): DeleteCustomerSuccess => ({
      __typename: 'DeleteCustomerSuccess',
      ...t
    })
  

    export const deleteFormError = (t: Omit<DeleteFormError, '__typename'>): DeleteFormError => ({
      __typename: 'DeleteFormError',
      ...t
    })
  

    export const deleteFormSuccess = (t: Omit<DeleteFormSuccess, '__typename'>): DeleteFormSuccess => ({
      __typename: 'DeleteFormSuccess',
      ...t
    })
  

    export const deleteFormRevisionError = (t: Omit<DeleteFormRevisionError, '__typename'>): DeleteFormRevisionError => ({
      __typename: 'DeleteFormRevisionError',
      ...t
    })
  

    export const deleteFormRevisionSuccess = (t: Omit<DeleteFormRevisionSuccess, '__typename'>): DeleteFormRevisionSuccess => ({
      __typename: 'DeleteFormRevisionSuccess',
      ...t
    })
  

    export const deleteSubmissionError = (t: Omit<DeleteSubmissionError, '__typename'>): DeleteSubmissionError => ({
      __typename: 'DeleteSubmissionError',
      ...t
    })
  

    export const deleteSubmissionSuccess = (t: Omit<DeleteSubmissionSuccess, '__typename'>): DeleteSubmissionSuccess => ({
      __typename: 'DeleteSubmissionSuccess',
      ...t
    })
  

    export const deleteSubmissionRevisionError = (t: Omit<DeleteSubmissionRevisionError, '__typename'>): DeleteSubmissionRevisionError => ({
      __typename: 'DeleteSubmissionRevisionError',
      ...t
    })
  

    export const deleteSubmissionRevisionSuccess = (t: Omit<DeleteSubmissionRevisionSuccess, '__typename'>): DeleteSubmissionRevisionSuccess => ({
      __typename: 'DeleteSubmissionRevisionSuccess',
      ...t
    })
  

    export const deleteTagSuccess = (t: Omit<DeleteTagSuccess, '__typename'>): DeleteTagSuccess => ({
      __typename: 'DeleteTagSuccess',
      ...t
    })
  

    export const disableUserSuccess = (t: Omit<DisableUserSuccess, '__typename'>): DisableUserSuccess => ({
      __typename: 'DisableUserSuccess',
      ...t
    })
  

    export const enableUserSuccess = (t: Omit<EnableUserSuccess, '__typename'>): EnableUserSuccess => ({
      __typename: 'EnableUserSuccess',
      ...t
    })
  

    export const publishFormRevisionSuccess = (t: Omit<PublishFormRevisionSuccess, '__typename'>): PublishFormRevisionSuccess => ({
      __typename: 'PublishFormRevisionSuccess',
      ...t
    })
  

    export const publishSubmissionRevisionSuccess = (t: Omit<PublishSubmissionRevisionSuccess, '__typename'>): PublishSubmissionRevisionSuccess => ({
      __typename: 'PublishSubmissionRevisionSuccess',
      ...t
    })
  

    export const submissionRevisionAlreadyPublished = (t: Omit<SubmissionRevisionAlreadyPublished, '__typename'>): SubmissionRevisionAlreadyPublished => ({
      __typename: 'SubmissionRevisionAlreadyPublished',
      ...t
    })
  

    export const submissionRevisionInvalid = (t: Omit<SubmissionRevisionInvalid, '__typename'>): SubmissionRevisionInvalid => ({
      __typename: 'SubmissionRevisionInvalid',
      ...t
    })
  

    export const submissionRevisionNotFoundError = (t: Omit<SubmissionRevisionNotFoundError, '__typename'>): SubmissionRevisionNotFoundError => ({
      __typename: 'SubmissionRevisionNotFoundError',
      ...t
    })
  

    export const removeSelfError = (t: Omit<RemoveSelfError, '__typename'>): RemoveSelfError => ({
      __typename: 'RemoveSelfError',
      ...t
    })
  

    export const removeUsersSuccess = (t: Omit<RemoveUsersSuccess, '__typename'>): RemoveUsersSuccess => ({
      __typename: 'RemoveUsersSuccess',
      ...t
    })
  

    export const requestUploadUrlSuccess = (t: Omit<RequestUploadUrlSuccess, '__typename'>): RequestUploadUrlSuccess => ({
      __typename: 'RequestUploadUrlSuccess',
      ...t
    })
  

    export const storageNotDefined = (t: Omit<StorageNotDefined, '__typename'>): StorageNotDefined => ({
      __typename: 'StorageNotDefined',
      ...t
    })
  

    export const shareSubmissionPdfSuccess = (t: Omit<ShareSubmissionPdfSuccess, '__typename'>): ShareSubmissionPdfSuccess => ({
      __typename: 'ShareSubmissionPdfSuccess',
      ...t
    })
  

    export const storeSubmissionPdfSuccess = (t: Omit<StoreSubmissionPdfSuccess, '__typename'>): StoreSubmissionPdfSuccess => ({
      __typename: 'StoreSubmissionPdfSuccess',
      ...t
    })
  

    export const submissionFilenameCustomerComponent = (t: Omit<SubmissionFilenameCustomerComponent, '__typename'>): SubmissionFilenameCustomerComponent => ({
      __typename: 'SubmissionFilenameCustomerComponent',
      ...t
    })
  

    export const submissionFilenameDateComponent = (t: Omit<SubmissionFilenameDateComponent, '__typename'>): SubmissionFilenameDateComponent => ({
      __typename: 'SubmissionFilenameDateComponent',
      ...t
    })
  

    export const submissionFilenameFormFieldComponent = (t: Omit<SubmissionFilenameFormFieldComponent, '__typename'>): SubmissionFilenameFormFieldComponent => ({
      __typename: 'SubmissionFilenameFormFieldComponent',
      ...t
    })
  

    export const submissionFilenameSubmitterComponent = (t: Omit<SubmissionFilenameSubmitterComponent, '__typename'>): SubmissionFilenameSubmitterComponent => ({
      __typename: 'SubmissionFilenameSubmitterComponent',
      ...t
    })
  

    export const updateCustomerSuccess = (t: Omit<UpdateCustomerSuccess, '__typename'>): UpdateCustomerSuccess => ({
      __typename: 'UpdateCustomerSuccess',
      ...t
    })
  

    export const formNotFoundError = (t: Omit<FormNotFoundError, '__typename'>): FormNotFoundError => ({
      __typename: 'FormNotFoundError',
      ...t
    })
  

    export const updateFormDescriptionSuccess = (t: Omit<UpdateFormDescriptionSuccess, '__typename'>): UpdateFormDescriptionSuccess => ({
      __typename: 'UpdateFormDescriptionSuccess',
      ...t
    })
  

    export const updateFormSuccess = (t: Omit<UpdateFormSuccess, '__typename'>): UpdateFormSuccess => ({
      __typename: 'UpdateFormSuccess',
      ...t
    })
  

    export const updateFormRequireCustomerSuccess = (t: Omit<UpdateFormRequireCustomerSuccess, '__typename'>): UpdateFormRequireCustomerSuccess => ({
      __typename: 'UpdateFormRequireCustomerSuccess',
      ...t
    })
  

    export const updateFormSubmissionFilenameFormatError = (t: Omit<UpdateFormSubmissionFilenameFormatError, '__typename'>): UpdateFormSubmissionFilenameFormatError => ({
      __typename: 'UpdateFormSubmissionFilenameFormatError',
      ...t
    })
  

    export const updateFormSubmissionFilenameFormatSuccess = (t: Omit<UpdateFormSubmissionFilenameFormatSuccess, '__typename'>): UpdateFormSubmissionFilenameFormatSuccess => ({
      __typename: 'UpdateFormSubmissionFilenameFormatSuccess',
      ...t
    })
  

    export const updateFormTitleSuccess = (t: Omit<UpdateFormTitleSuccess, '__typename'>): UpdateFormTitleSuccess => ({
      __typename: 'UpdateFormTitleSuccess',
      ...t
    })
  

    export const updateLogoSuccess = (t: Omit<UpdateLogoSuccess, '__typename'>): UpdateLogoSuccess => ({
      __typename: 'UpdateLogoSuccess',
      ...t
    })
  

    export const customerNotFoundError = (t: Omit<CustomerNotFoundError, '__typename'>): CustomerNotFoundError => ({
      __typename: 'CustomerNotFoundError',
      ...t
    })
  

    export const updateSubmissionCustomerSuccess = (t: Omit<UpdateSubmissionCustomerSuccess, '__typename'>): UpdateSubmissionCustomerSuccess => ({
      __typename: 'UpdateSubmissionCustomerSuccess',
      ...t
    })
  

    export const updateSubmissionLocationSuccess = (t: Omit<UpdateSubmissionLocationSuccess, '__typename'>): UpdateSubmissionLocationSuccess => ({
      __typename: 'UpdateSubmissionLocationSuccess',
      ...t
    })
  

    export const updatePublishedSubmissionRevisionError = (t: Omit<UpdatePublishedSubmissionRevisionError, '__typename'>): UpdatePublishedSubmissionRevisionError => ({
      __typename: 'UpdatePublishedSubmissionRevisionError',
      ...t
    })
  

    export const updateSubmissionRevisionSuccess = (t: Omit<UpdateSubmissionRevisionSuccess, '__typename'>): UpdateSubmissionRevisionSuccess => ({
      __typename: 'UpdateSubmissionRevisionSuccess',
      ...t
    })
  

    export const updateTagSuccess = (t: Omit<UpdateTagSuccess, '__typename'>): UpdateTagSuccess => ({
      __typename: 'UpdateTagSuccess',
      ...t
    })
  

    export const updateUserSuccess = (t: Omit<UpdateUserSuccess, '__typename'>): UpdateUserSuccess => ({
      __typename: 'UpdateUserSuccess',
      ...t
    })
  

    export const updateViewerSuccess = (t: Omit<UpdateViewerSuccess, '__typename'>): UpdateViewerSuccess => ({
      __typename: 'UpdateViewerSuccess',
      ...t
    })
  

  export const isAddUserToOrganizationSuccess = (a: AnyType): a is AddUserToOrganizationSuccess =>
    a.__typename === 'AddUserToOrganizationSuccess'
  

  export const isUserAlreadyAddedError = (a: AnyType): a is UserAlreadyAddedError =>
    a.__typename === 'UserAlreadyAddedError'
  

  export const isCreateCustomerSuccess = (a: AnyType): a is CreateCustomerSuccess =>
    a.__typename === 'CreateCustomerSuccess'
  

  export const isCreateFormSuccess = (a: AnyType): a is CreateFormSuccess =>
    a.__typename === 'CreateFormSuccess'
  

  export const isCreateSubmissionSuccess = (a: AnyType): a is CreateSubmissionSuccess =>
    a.__typename === 'CreateSubmissionSuccess'
  

  export const isSubmissionNotFoundError = (a: AnyType): a is SubmissionNotFoundError =>
    a.__typename === 'SubmissionNotFoundError'
  

  export const isCreateSubmissionRevisionSuccess = (a: AnyType): a is CreateSubmissionRevisionSuccess =>
    a.__typename === 'CreateSubmissionRevisionSuccess'
  

  export const isCreateTagSuccess = (a: AnyType): a is CreateTagSuccess =>
    a.__typename === 'CreateTagSuccess'
  

  export const isTagExistsError = (a: AnyType): a is TagExistsError =>
    a.__typename === 'TagExistsError'
  

  export const isDeleteCustomerSuccess = (a: AnyType): a is DeleteCustomerSuccess =>
    a.__typename === 'DeleteCustomerSuccess'
  

  export const isDeleteFormError = (a: AnyType): a is DeleteFormError =>
    a.__typename === 'DeleteFormError'
  

  export const isDeleteFormSuccess = (a: AnyType): a is DeleteFormSuccess =>
    a.__typename === 'DeleteFormSuccess'
  

  export const isDeleteFormRevisionError = (a: AnyType): a is DeleteFormRevisionError =>
    a.__typename === 'DeleteFormRevisionError'
  

  export const isDeleteFormRevisionSuccess = (a: AnyType): a is DeleteFormRevisionSuccess =>
    a.__typename === 'DeleteFormRevisionSuccess'
  

  export const isDeleteSubmissionError = (a: AnyType): a is DeleteSubmissionError =>
    a.__typename === 'DeleteSubmissionError'
  

  export const isDeleteSubmissionSuccess = (a: AnyType): a is DeleteSubmissionSuccess =>
    a.__typename === 'DeleteSubmissionSuccess'
  

  export const isDeleteSubmissionRevisionError = (a: AnyType): a is DeleteSubmissionRevisionError =>
    a.__typename === 'DeleteSubmissionRevisionError'
  

  export const isDeleteSubmissionRevisionSuccess = (a: AnyType): a is DeleteSubmissionRevisionSuccess =>
    a.__typename === 'DeleteSubmissionRevisionSuccess'
  

  export const isDeleteTagSuccess = (a: AnyType): a is DeleteTagSuccess =>
    a.__typename === 'DeleteTagSuccess'
  

  export const isDisableUserSuccess = (a: AnyType): a is DisableUserSuccess =>
    a.__typename === 'DisableUserSuccess'
  

  export const isEnableUserSuccess = (a: AnyType): a is EnableUserSuccess =>
    a.__typename === 'EnableUserSuccess'
  

  export const isPublishFormRevisionSuccess = (a: AnyType): a is PublishFormRevisionSuccess =>
    a.__typename === 'PublishFormRevisionSuccess'
  

  export const isPublishSubmissionRevisionSuccess = (a: AnyType): a is PublishSubmissionRevisionSuccess =>
    a.__typename === 'PublishSubmissionRevisionSuccess'
  

  export const isSubmissionRevisionAlreadyPublished = (a: AnyType): a is SubmissionRevisionAlreadyPublished =>
    a.__typename === 'SubmissionRevisionAlreadyPublished'
  

  export const isSubmissionRevisionInvalid = (a: AnyType): a is SubmissionRevisionInvalid =>
    a.__typename === 'SubmissionRevisionInvalid'
  

  export const isSubmissionRevisionNotFoundError = (a: AnyType): a is SubmissionRevisionNotFoundError =>
    a.__typename === 'SubmissionRevisionNotFoundError'
  

  export const isRemoveSelfError = (a: AnyType): a is RemoveSelfError =>
    a.__typename === 'RemoveSelfError'
  

  export const isRemoveUsersSuccess = (a: AnyType): a is RemoveUsersSuccess =>
    a.__typename === 'RemoveUsersSuccess'
  

  export const isRequestUploadUrlSuccess = (a: AnyType): a is RequestUploadUrlSuccess =>
    a.__typename === 'RequestUploadUrlSuccess'
  

  export const isStorageNotDefined = (a: AnyType): a is StorageNotDefined =>
    a.__typename === 'StorageNotDefined'
  

  export const isShareSubmissionPdfSuccess = (a: AnyType): a is ShareSubmissionPdfSuccess =>
    a.__typename === 'ShareSubmissionPdfSuccess'
  

  export const isStoreSubmissionPdfSuccess = (a: AnyType): a is StoreSubmissionPdfSuccess =>
    a.__typename === 'StoreSubmissionPdfSuccess'
  

  export const isSubmissionFilenameCustomerComponent = (a: AnyType): a is SubmissionFilenameCustomerComponent =>
    a.__typename === 'SubmissionFilenameCustomerComponent'
  

  export const isSubmissionFilenameDateComponent = (a: AnyType): a is SubmissionFilenameDateComponent =>
    a.__typename === 'SubmissionFilenameDateComponent'
  

  export const isSubmissionFilenameFormFieldComponent = (a: AnyType): a is SubmissionFilenameFormFieldComponent =>
    a.__typename === 'SubmissionFilenameFormFieldComponent'
  

  export const isSubmissionFilenameSubmitterComponent = (a: AnyType): a is SubmissionFilenameSubmitterComponent =>
    a.__typename === 'SubmissionFilenameSubmitterComponent'
  

  export const isUpdateCustomerSuccess = (a: AnyType): a is UpdateCustomerSuccess =>
    a.__typename === 'UpdateCustomerSuccess'
  

  export const isFormNotFoundError = (a: AnyType): a is FormNotFoundError =>
    a.__typename === 'FormNotFoundError'
  

  export const isUpdateFormDescriptionSuccess = (a: AnyType): a is UpdateFormDescriptionSuccess =>
    a.__typename === 'UpdateFormDescriptionSuccess'
  

  export const isUpdateFormSuccess = (a: AnyType): a is UpdateFormSuccess =>
    a.__typename === 'UpdateFormSuccess'
  

  export const isUpdateFormRequireCustomerSuccess = (a: AnyType): a is UpdateFormRequireCustomerSuccess =>
    a.__typename === 'UpdateFormRequireCustomerSuccess'
  

  export const isUpdateFormSubmissionFilenameFormatError = (a: AnyType): a is UpdateFormSubmissionFilenameFormatError =>
    a.__typename === 'UpdateFormSubmissionFilenameFormatError'
  

  export const isUpdateFormSubmissionFilenameFormatSuccess = (a: AnyType): a is UpdateFormSubmissionFilenameFormatSuccess =>
    a.__typename === 'UpdateFormSubmissionFilenameFormatSuccess'
  

  export const isUpdateFormTitleSuccess = (a: AnyType): a is UpdateFormTitleSuccess =>
    a.__typename === 'UpdateFormTitleSuccess'
  

  export const isUpdateLogoSuccess = (a: AnyType): a is UpdateLogoSuccess =>
    a.__typename === 'UpdateLogoSuccess'
  

  export const isCustomerNotFoundError = (a: AnyType): a is CustomerNotFoundError =>
    a.__typename === 'CustomerNotFoundError'
  

  export const isUpdateSubmissionCustomerSuccess = (a: AnyType): a is UpdateSubmissionCustomerSuccess =>
    a.__typename === 'UpdateSubmissionCustomerSuccess'
  

  export const isUpdateSubmissionLocationSuccess = (a: AnyType): a is UpdateSubmissionLocationSuccess =>
    a.__typename === 'UpdateSubmissionLocationSuccess'
  

  export const isUpdatePublishedSubmissionRevisionError = (a: AnyType): a is UpdatePublishedSubmissionRevisionError =>
    a.__typename === 'UpdatePublishedSubmissionRevisionError'
  

  export const isUpdateSubmissionRevisionSuccess = (a: AnyType): a is UpdateSubmissionRevisionSuccess =>
    a.__typename === 'UpdateSubmissionRevisionSuccess'
  

  export const isUpdateTagSuccess = (a: AnyType): a is UpdateTagSuccess =>
    a.__typename === 'UpdateTagSuccess'
  

  export const isUpdateUserSuccess = (a: AnyType): a is UpdateUserSuccess =>
    a.__typename === 'UpdateUserSuccess'
  

  export const isUpdateViewerSuccess = (a: AnyType): a is UpdateViewerSuccess =>
    a.__typename === 'UpdateViewerSuccess'
  