import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FormRevisionListRevisionFragmentDoc } from '../../../components/FormRevisionList/__generated__/FormRevisionList';
import { SubmissionFilenameFormFragmentDoc } from '../SubmissionFilenameForm/__generated__/SubmissionFilenameForm';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type FormDetailsPageFormFragment = { __typename: 'Form', id: string, title: string, description: string | null, requireCustomer: boolean, latestRevision: { __typename: 'FormRevision', definition: any, id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }, active: { __typename: 'FormRevision', id: string } | null, revisions: Array<{ __typename: 'FormRevision', id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }>, filenameFormat: Array<{ __typename: 'SubmissionFilenameCustomerComponent', customerProperty: SchemaTypes.SubmissionFilenameCustomerProperty } | { __typename: 'SubmissionFilenameDateComponent', dateFormat: SchemaTypes.SubmissionFilenameDateFormat } | { __typename: 'SubmissionFilenameFormFieldComponent', fieldKey: string } | { __typename: 'SubmissionFilenameSubmitterComponent', submitterProperty: SchemaTypes.SubmissionFilenameSubmitterProperty }> };

export type FormDetailsPageQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type FormDetailsPageQuery = { __typename: 'Query', form: { __typename: 'Form', id: string, title: string, description: string | null, requireCustomer: boolean, latestRevision: { __typename: 'FormRevision', definition: any, id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }, active: { __typename: 'FormRevision', id: string } | null, revisions: Array<{ __typename: 'FormRevision', id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }>, filenameFormat: Array<{ __typename: 'SubmissionFilenameCustomerComponent', customerProperty: SchemaTypes.SubmissionFilenameCustomerProperty } | { __typename: 'SubmissionFilenameDateComponent', dateFormat: SchemaTypes.SubmissionFilenameDateFormat } | { __typename: 'SubmissionFilenameFormFieldComponent', fieldKey: string } | { __typename: 'SubmissionFilenameSubmitterComponent', submitterProperty: SchemaTypes.SubmissionFilenameSubmitterProperty }> } };

export type UpdateFormMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateFormInput;
}>;


export type UpdateFormMutation = { __typename: 'Mutation', updateForm: { __typename: 'FormNotFoundError' } | { __typename: 'UpdateFormSuccess', form: { __typename: 'Form', id: string, revisions: Array<{ __typename: 'FormRevision', id: string, definition: any }> } } };

export type SubmitFormMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateSubmissionInput;
}>;


export type SubmitFormMutation = { __typename: 'Mutation', createSubmission: { __typename: 'CreateSubmissionSuccess', submission: { __typename: 'Submission', id: string } } | { __typename: 'SubmissionNotFoundError' } };

export type DeleteFormMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeleteFormInput;
}>;


export type DeleteFormMutation = { __typename: 'Mutation', deleteForm: { __typename: 'DeleteFormError', success: boolean, message: string } | { __typename: 'DeleteFormSuccess', success: boolean } };

export type UpdateFormTitleMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateFormTitleInput;
}>;


export type UpdateFormTitleMutation = { __typename: 'Mutation', updateFormTitle: { __typename: 'FormNotFoundError' } | { __typename: 'UpdateFormTitleSuccess', form: { __typename: 'Form', id: string, title: string, description: string | null, requireCustomer: boolean, latestRevision: { __typename: 'FormRevision', definition: any, id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }, active: { __typename: 'FormRevision', id: string } | null, revisions: Array<{ __typename: 'FormRevision', id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }>, filenameFormat: Array<{ __typename: 'SubmissionFilenameCustomerComponent', customerProperty: SchemaTypes.SubmissionFilenameCustomerProperty } | { __typename: 'SubmissionFilenameDateComponent', dateFormat: SchemaTypes.SubmissionFilenameDateFormat } | { __typename: 'SubmissionFilenameFormFieldComponent', fieldKey: string } | { __typename: 'SubmissionFilenameSubmitterComponent', submitterProperty: SchemaTypes.SubmissionFilenameSubmitterProperty }> } } };

export type UpdateFormDescriptionMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateFormDescriptionInput;
}>;


export type UpdateFormDescriptionMutation = { __typename: 'Mutation', updateFormDescription: { __typename: 'FormNotFoundError' } | { __typename: 'UpdateFormDescriptionSuccess', form: { __typename: 'Form', id: string, title: string, description: string | null, requireCustomer: boolean, latestRevision: { __typename: 'FormRevision', definition: any, id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }, active: { __typename: 'FormRevision', id: string } | null, revisions: Array<{ __typename: 'FormRevision', id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }>, filenameFormat: Array<{ __typename: 'SubmissionFilenameCustomerComponent', customerProperty: SchemaTypes.SubmissionFilenameCustomerProperty } | { __typename: 'SubmissionFilenameDateComponent', dateFormat: SchemaTypes.SubmissionFilenameDateFormat } | { __typename: 'SubmissionFilenameFormFieldComponent', fieldKey: string } | { __typename: 'SubmissionFilenameSubmitterComponent', submitterProperty: SchemaTypes.SubmissionFilenameSubmitterProperty }> } } };

export type UpdateFormCustomerRequiredMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateFormRequireCustomerInput;
}>;


export type UpdateFormCustomerRequiredMutation = { __typename: 'Mutation', updateFormRequireCustomer: { __typename: 'FormNotFoundError' } | { __typename: 'UpdateFormRequireCustomerSuccess', form: { __typename: 'Form', id: string, title: string, description: string | null, requireCustomer: boolean, latestRevision: { __typename: 'FormRevision', definition: any, id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }, active: { __typename: 'FormRevision', id: string } | null, revisions: Array<{ __typename: 'FormRevision', id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }>, filenameFormat: Array<{ __typename: 'SubmissionFilenameCustomerComponent', customerProperty: SchemaTypes.SubmissionFilenameCustomerProperty } | { __typename: 'SubmissionFilenameDateComponent', dateFormat: SchemaTypes.SubmissionFilenameDateFormat } | { __typename: 'SubmissionFilenameFormFieldComponent', fieldKey: string } | { __typename: 'SubmissionFilenameSubmitterComponent', submitterProperty: SchemaTypes.SubmissionFilenameSubmitterProperty }> } } };

export const FormDetailsPageFormFragmentDoc = {"kind":"Document", "definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormDetailsPageFormFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Form"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"requireCustomer"}},{"kind":"Field","name":{"kind":"Name","value":"latestRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"definition"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}},{"kind":"Field","name":{"kind":"Name","value":"active"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"revisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormRevisionListRevisionFragment"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SubmissionFilenameFormFragment"}}]}},...FormRevisionListRevisionFragmentDoc.definitions,...SubmissionFilenameFormFragmentDoc.definitions]} as unknown as DocumentNode<FormDetailsPageFormFragment, unknown>;
export const FormDetailsPageQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FormDetailsPageQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormDetailsPageFormFragment"}}]}}]}},...FormDetailsPageFormFragmentDoc.definitions]} as unknown as DocumentNode<FormDetailsPageQuery, FormDetailsPageQueryVariables>;
export const UpdateFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateForm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"revisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"definition"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<UpdateFormMutation, UpdateFormMutationVariables>;
export const SubmitFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SubmitForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateSubmissionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSubmission"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CreateSubmissionSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submission"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<SubmitFormMutation, SubmitFormMutationVariables>;
export const DeleteFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteFormInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteForm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteFormSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteFormError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<DeleteFormMutation, DeleteFormMutationVariables>;
export const UpdateFormTitleDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFormTitle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormTitleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFormTitle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormTitleSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormDetailsPageFormFragment"}}]}}]}}]}}]}},...FormDetailsPageFormFragmentDoc.definitions]} as unknown as DocumentNode<UpdateFormTitleMutation, UpdateFormTitleMutationVariables>;
export const UpdateFormDescriptionDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFormDescription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormDescriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFormDescription"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormDescriptionSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormDetailsPageFormFragment"}}]}}]}}]}}]}},...FormDetailsPageFormFragmentDoc.definitions]} as unknown as DocumentNode<UpdateFormDescriptionMutation, UpdateFormDescriptionMutationVariables>;
export const UpdateFormCustomerRequiredDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFormCustomerRequired"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormRequireCustomerInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFormRequireCustomer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFormRequireCustomerSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormDetailsPageFormFragment"}}]}}]}}]}}]}},...FormDetailsPageFormFragmentDoc.definitions]} as unknown as DocumentNode<UpdateFormCustomerRequiredMutation, UpdateFormCustomerRequiredMutationVariables>;