import {
  ValueOfFiltersConfig,
  dateRangeFilter,
  filters,
  formFilter,
  tagFilter,
  userFilter,
  useFiltersContext,
  useFilters,
  customerFilter,
  queryFilter
} from '../filters'

export const submissionFilters = filters({
  query: queryFilter('Search by submission data'),
  creatorId: userFilter('Submitter'),
  formId: formFilter('Form'),
  published: dateRangeFilter('Publish date'),
  tags: tagFilter('Tags'),
  customerId: customerFilter('Customer')
})

export const useSubmissionFilters = (
  initialValues: Partial<SubmissionListFilters> = {}
) => useFilters(submissionFilters, initialValues)

export type SubmissionListFiltersConfig = typeof submissionFilters

type SubmissionListFilters = ValueOfFiltersConfig<typeof submissionFilters>

export type SubmissionFilter = keyof SubmissionListFilters

export const useSubmissionListFiltersContext = () =>
  useFiltersContext<SubmissionListFiltersConfig>()
