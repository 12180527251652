import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type SelectCustomerModalCustomerFragment = { __typename: 'Customer', id: string, name: string };

export type SelectCustomerModalCustomerListPageFragment = { __typename: 'CustomerListPage', pageInfo: { __typename: 'PageInfo', totalCount: number }, data: Array<{ __typename: 'Customer', id: string, name: string }> };

export type SelectCustomerModalQueryVariables = SchemaTypes.Exact<{
  query: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
  limit: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']>;
}>;


export type SelectCustomerModalQuery = { __typename: 'Query', customers: { __typename: 'CustomerListPage', pageInfo: { __typename: 'PageInfo', totalCount: number }, data: Array<{ __typename: 'Customer', id: string, name: string }> } };

export const SelectCustomerModalCustomerFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SelectCustomerModalCustomerFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Customer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<SelectCustomerModalCustomerFragment, unknown>;
export const SelectCustomerModalCustomerListPageFragmentDoc = {"kind":"Document", "definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SelectCustomerModalCustomerListPageFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CustomerListPage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalCount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SelectCustomerModalCustomerFragment"}}]}}]}},...SelectCustomerModalCustomerFragmentDoc.definitions]} as unknown as DocumentNode<SelectCustomerModalCustomerListPageFragment, unknown>;
export const SelectCustomerModalQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SelectCustomerModalQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SelectCustomerModalCustomerListPageFragment"}}]}}]}},...SelectCustomerModalCustomerListPageFragmentDoc.definitions]} as unknown as DocumentNode<SelectCustomerModalQuery, SelectCustomerModalQueryVariables>;