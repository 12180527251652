import {
  Box,
  H4,
  Text,
  Subtitle,
  Separator,
  PrimaryButton,
  DialogSidebarContent,
  SecondaryButton,
  Flex,
  DownloadIcon,
  ExternalIcon,
  SmallBody
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useQuery } from 'urql'
import { useParams } from 'react-router'
import { Location } from 'history'
import { useOrganization } from '@woorcs/graphql'
import { MapContainer, Marker } from 'react-leaflet'
import {
  type LayerProps,
  createElementObject,
  createTileLayerComponent,
  updateGridLayer,
  withPane
} from '@react-leaflet/core'
import L from 'leaflet'
import { Suspense } from 'react'
import 'leaflet/dist/leaflet.css'

import { pdfUrl } from '@app/utils/router'

import { SubmissionRevisionList } from '../SubmissionRevisionList'
import { SidebarRoute } from '../SidebarRoute'
import { ShareSubmissionPdfModal } from '../ShareSubmissionPdfModal'
import { LanguageLabel } from '../LanguageLabel'
import { FullscreenLoader } from '../FullscreenLoader'

import {
  SubmissionDetailsSidebarLocationFragment,
  SubmissionDetailsSidebarQueryDocument
} from './__generated__/SubmissionDetailsSidebar'

import '@maplibre/maplibre-gl-leaflet'

export interface MapLibreTileLayerProps
  extends L.LeafletMaplibreGLOptions,
    LayerProps {
  url: string
  attribution: string
}

export const MapLibreTileLayer = createTileLayerComponent<
  L.MaplibreGL,
  MapLibreTileLayerProps
>(
  function createTileLayer({ url, attribution, ...options }, context) {
    const layer = L.maplibreGL(
      { style: url, attribution, noWrap: true },
      // @ts-expect-error fix types
      withPane(options, context)
    )
    return createElementObject(layer, context)
  },
  function updateTileLayer(layer, props, prevProps) {
    updateGridLayer(layer as any, props as any, prevProps)

    const { url, attribution } = props
    if (url != null && url !== prevProps.url) {
      layer.getMaplibreMap().setStyle(url)
    }

    if (attribution != null && attribution !== prevProps.attribution) {
      layer.options.attribution = attribution
    }
  }
)

interface SubmissionLocationMapProps {
  location: SubmissionDetailsSidebarLocationFragment
}

const SubmissionLocationMap = ({ location }: SubmissionLocationMapProps) => {
  return (
    <Box mb={4}>
      <Box mb={2} px={8}>
        <MapContainer
          style={{ height: 200 }}
          center={[location.latitude, location.longitude]}
          zoom={13}
          attributionControl={false}
          scrollWheelZoom={false}
        >
          <MapLibreTileLayer
            attribution=''
            url='https://tiles.stadiamaps.com/styles/outdoors.json'
          />
          <Marker position={[location.latitude, location.longitude]} />
        </MapContainer>
      </Box>
      <Box px={8} pb={4}>
        <SmallBody>Testvägen 123, 123 45 Testberga</SmallBody>
      </Box>

      <Separator mb={4} />
    </Box>
  )
}

export const SubmissionDetailsSidebarContent = () => {
  const { submissionId } = useParams()
  const organization = useOrganization()

  const { t } = useTranslation()
  const [query] = useQuery({
    query: SubmissionDetailsSidebarQueryDocument,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      submissionId: submissionId!
    }
  })
  const submission = query.data?.submission

  if (!submission) {
    return null
  }

  return (
    <Box py={12}>
      <Box px={8}>
        <H4 mb={6}>
          {t('submissions:submissionDetailsSidebar.title')} #{submission.id}
        </H4>

        <Box mb={6}>
          <Flex gridColumnGap={6} flexWrap='wrap'>
            <Text fontSize='small'>
              <Text fontWeight='bold' mr={1}>
                {t('submissions:submissionDetailsSidebar.submittedBy')}:
              </Text>
              <Text>{submission.user.name}</Text>
            </Text>

            <Text fontSize='small'>
              <Text fontWeight='bold' mr={1}>
                {t('common:date')}:
              </Text>
              <Text>
                {format(new Date(submission.current.createdAt), 'yyyy-MM-dd')}
              </Text>
            </Text>
            {submission.customer && (
              <Text fontSize='small'>
                <Text fontWeight='bold' mr={1}>
                  {t('submissions:submissionDetailsSidebar.customer')}:
                </Text>
                <Text>{submission.customer.name}</Text>
              </Text>
            )}
            {submission.language && (
              <Flex alignItems='center'>
                <Text fontSize='small' fontWeight='bold' mr={2}>
                  {t('common:language')}:
                </Text>
                <Box fontSize='small'>
                  <LanguageLabel locale={submission.language} />
                </Box>
              </Flex>
            )}
          </Flex>
        </Box>
      </Box>

      <Separator mb={4} />

      {submission.location && (
        <SubmissionLocationMap location={submission.location} />
      )}

      <Flex flexDirection='column' mb={4} px={8} gridRowGap={2}>
        <PrimaryButton
          as='a'
          width='100%'
          leftIcon={<DownloadIcon />}
          {...({
            href: pdfUrl(organization.id, submission.current.id),
            target: '_blank'
          } as any)}
        >
          {t('common:download')}
        </PrimaryButton>

        <ShareSubmissionPdfModal submissionRevisionId={submission.current.id}>
          <SecondaryButton width='100%' leftIcon={<ExternalIcon />}>
            {t('submissions:submissionDetailsSidebar.shareSubmission')}
          </SecondaryButton>
        </ShareSubmissionPdfModal>
      </Flex>

      <Separator mb={8} />

      <Box px={6}>
        <Box px={2}>
          <Subtitle mb={4}>{t('common:history')}</Subtitle>
        </Box>

        <SubmissionRevisionList
          submissionId={submission.id}
          revisions={submission?.revisions}
        />
      </Box>
    </Box>
  )
}

type SubmissionDetailsSidebarProps = {
  prevLocation: Location
}

export const SubmissionDetailsSidebar = ({
  prevLocation
}: SubmissionDetailsSidebarProps) => {
  return (
    <SidebarRoute prevLocation={prevLocation}>
      <DialogSidebarContent aria-label='Submission'>
        <Suspense fallback={<FullscreenLoader />}>
          <SubmissionDetailsSidebarContent />
        </Suspense>
      </DialogSidebarContent>
    </SidebarRoute>
  )
}
