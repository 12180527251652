import {
  IconButton,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem,
  MoreIcon,
  ConfirmAlertDialog
} from '@woorcs/design-system'
import { ReactElement, useState, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { EditCustomerModal } from '@app/components/EditCustomerModal'
import { EditCustomerModalFragment } from '@app/components/EditCustomerModal/__generated__/EditCustomerModalFragments'

type ConfirmRemoveAlertProps = {
  customer: EditCustomerModalFragment
  children: ReactElement
  onConfirm(): void
}

const ConfirmRemoveAlert = memo(
  ({ customer, onConfirm, children }: ConfirmRemoveAlertProps) => {
    return (
      <ConfirmAlertDialog
        title={`Ta bort ${customer.name}`}
        body='Är du säker på att du vill ta bort den här kunden?'
        confirmLabel='Ta bort'
        onConfirm={onConfirm}
      >
        {children}
      </ConfirmAlertDialog>
    )
  }
)

type CustomerRowMenuProps = {
  customer: EditCustomerModalFragment
  onRemove(): void
}

export const CustomerRowMenu = memo(
  ({ customer, onRemove }: CustomerRowMenuProps) => {
    const { t } = useTranslation()
    const [visible, setVisible] = useState(false)

    return (
      <Menu placement='bottom-end' open={visible} setOpen={setVisible}>
        <MenuButton>
          <IconButton color='primary' display='inline-flex'>
            <MoreIcon />
          </IconButton>
        </MenuButton>

        <MenuContent aria-label='User menu'>
          <EditCustomerModal key={customer.id} customer={customer}>
            <MenuItem hideOnClick={false}>{t('common:edit')}</MenuItem>
          </EditCustomerModal>

          <ConfirmRemoveAlert customer={customer} onConfirm={onRemove}>
            <MenuItem hideOnClick={false} destructive>
              {t('common:remove')}
            </MenuItem>
          </ConfirmRemoveAlert>
        </MenuContent>
      </Menu>
    )
  }
)
