import { useDebouncedCallback } from 'use-debounce'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

interface AutoSaveProps {
  debounceMs: number
}

export const AutoSave = ({ debounceMs }: AutoSaveProps) => {
  const formik = useFormikContext()
  const debouncedSubmit = useDebouncedCallback(
    () => formik.submitForm(),
    debounceMs
  )

  useEffect(() => {
    if (!formik.dirty) {
      return
    }

    debouncedSubmit.callback()
  }, [debouncedSubmit, formik.dirty, formik.values])

  return null
}
