import { useCallback, useState, ReactElement, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import {
  Box,
  Input,
  Modal,
  ModalBody,
  ModalDialog,
  ModalDisclosure,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  useSnackbar
} from '@woorcs/design-system/src'
import * as t from 'io-ts'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { getFormikValidator } from '@woorcs/utils/formik'

import { FormField } from '../Formik'

import { EditCustomerModalFragment } from './__generated__/EditCustomerModalFragments'
import { UpdateCustomerDocument } from './__generated__/EditCustomerModal'

interface Props {
  customer: EditCustomerModalFragment
  children: ReactElement
}

const CustomerProfileForm = t.type({
  name: t.string
})

export type CustomerProfileFormCustomer = t.TypeOf<typeof CustomerProfileForm>

const validateForm = getFormikValidator(CustomerProfileForm)

export const EditCustomerModal = ({ customer, children }: Props) => {
  const { t } = useTranslation()
  const customerId = customer.id
  const [visible, setVisible] = useState(false)
  const snackbar = useSnackbar()
  const [, updateCustomer] = useMutation(UpdateCustomerDocument)
  const initialValues = useMemo(
    () => ({
      name: customer.name
    }),
    [customer]
  )

  const handleSubmit = useCallback(
    (
      form: CustomerProfileFormCustomer,
      actions: FormikHelpers<CustomerProfileFormCustomer>
    ) => {
      updateCustomer({
        input: {
          id: customerId,
          name: form.name
        }
      })
        .then(() => {
          setVisible(false)

          snackbar.showSnackbar({
            title: 'Customer updated',
            variant: 'success'
          })

          actions.resetForm({
            values: form
          })
        })
        .catch(() => {
          snackbar.showSnackbar({
            title: 'Failed to update customer',
            variant: 'danger'
          })
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [snackbar, updateCustomer, customer, customerId]
  )

  return (
    <Modal open={visible} setOpen={setVisible}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog role='dialog'>
        <ModalHeader>
          <ModalTitle mb={6}>
            {t('customers:editCustomerModal.title')}
          </ModalTitle>
        </ModalHeader>

        <Formik
          validate={validateForm}
          initialValues={initialValues as any}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, values, setFieldValue }) => (
            <Form>
              <ModalBody>
                <FormField name='name' label={t('common:name')}>
                  <Box>
                    <Input
                      value={values.name}
                      onChange={(value) => setFieldValue('role', value)}
                    />
                  </Box>
                </FormField>
              </ModalBody>

              <ModalFooter>
                <ModalDismiss render={<SecondaryButton mr={2} />}>
                  {t('common:cancel')}
                </ModalDismiss>
                <PrimaryButton
                  type='submit'
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  {t('common:save')}
                </PrimaryButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
